import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ItemService from "../../services/ItemService.js";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getItems = createAsyncThunk(
    "items/all",
    async () => {
        const res = await ItemService.getAll();
        return res.data;
    }
);

export const addItem = createAsyncThunk(
    "items/add",
    async (item) => {
        const res = await ItemService.add(item);
        return res.data;
    }
);

export const editItem = createAsyncThunk(
    "items/edit",
    async (item) => {
        const res = await ItemService.edit(item);
        return res.data;
    }
);


const itemSlice = createSlice({
    name: "items",
    initialState,
    extraReducers: {
        [getItems.fulfilled]: (state, action) => {
            return action.payload
        },
        [addItem.fulfilled]: (state, action) => {
            if (state===null) {
                state = [action.payload.data];
            } else {
                state.push(action.payload.data)
            }
            return state
        },
        [editItem.fulfilled]: (state, action) => {
            const item = action.payload.data
            return state.map((i) => {
                if (i.id===item.id) return item
                return i
            })
        },
    },
});

const { reducer } = itemSlice;
export default reducer;