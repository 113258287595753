/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../components/AdminContainer";
import CircularLoader from "../components/loader/CircularLoader";
import useLanguage from "../hook/useLanguage";
import { getGames } from "../redux/slices/gameSlice";
import { getBestVote, getModelById, parseReadableDate } from "../utils/utils";
import AddVoteForm from "../components/form/game/AddVoteForm";
import EditVoteForm from "../components/form/game/EditVoteForm";
import { getVotes, updateVote } from "../redux/slices/voteSlice";
import { getPlayers } from "../redux/slices/playerSlice";
import "./../styles/ShopPage.css";
import "./../styles/GamePage.css";
import "./../styles/VotePage.css";
import { toast } from "react-toastify";
import { VoteRanking } from "../components/vote/VoteRanking";
import { Modal } from "../components/Modal";
import TabPane from "../components/navigation/TabPane";
import ManOfMatch from "../components/vote/ManOfMatch";
import BestPlayer from "../components/vote/BestPlayer";
import NoVote from "../components/vote/NoVote";

const VotePage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const gameData = useSelector((state) => state.game);
  const voteData = useSelector((state) => state.vote);
  const userData = useSelector((state) => state.user);
  const playerData = useSelector((state) => state.player);

  const [votes, setVotes] = useState([]);
  const [games, setGames] = useState([]);
  const [players, setPlayers] = useState([]);

  const dispatch = useDispatch();

  const shopCartRef = useRef();
  const [step, setStep] = useState(0);
  const [onAdd, setOnAdd] = useState(false);
  const [toEdit, setToEdit] = useState(false);

  useEffect(() => {
    if (!gameData || gameData.length < 1) {
      dispatch(getGames())
      .unwrap()
      .then((data) => {
          setGames(data);
        });
      } else {
      setGames(gameData);
    }
    if (!voteData || voteData.length < 1) {
      dispatch(getVotes())
        .unwrap()
        .then((data) => {
          setVotes(data);
        });
    } else {
      setVotes(voteData);
    }
    if (!playerData || playerData.length < 1) {
      dispatch(getPlayers())
        .unwrap()
        .then((data) => {
          setPlayers(data);
        });
    } else {
      setPlayers(playerData);
    }
  }, [gameData, voteData, playerData]);

  const [confirm, setConfirm] = useState(null);

  const handleActiveVote = (e, vote) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirm({
      title: "Confirmation",
      message: vote.active
        ? `Voulez vous désactivez le vote ${vote.name} ?`
        : `Voulez vous activez le vote ${vote.name} ?`,
      callback: function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.target.setAttribute("disabled", "true");
        e.target.innerHTML =
          "<div className='spinner-grow spinner-grow-sm' role='status'></div>";

        // active vote
        if (vote.game_id === null) {
          vote = {
            id: vote.id,
            active: vote.active,
          };
        }
        dispatch(
          updateVote({
            ...vote,
            active: !vote.active,
          })
        )
          .unwrap()
          .then((data) => {
            toast.success("Le vote a été modifié.");
          })
          .catch((err) => {
            console.log(err);
            toast.error("Nous avons rencontrée une erreur.");
          })
          .finally(() => {
            setConfirm(null);
          });
      },
    });
  };

  const [newVotePlayersId, setNewVotePlayersId] = useState([]);
  const [voteResult, setVoteResult] = useState(null);
  const [previewImageSrc, setPreviewImageSrc] = useState();

  const voteDataColumns = [
    {
      name: "#",
      selector: (row) => {
        return row.id;
      },
      sortable: true,
    },
    {
      name: "Label",
      selector: (row) => {
        return <span className="">{row.name}</span>;
      },
    },
    {
      name: "Résultat",
      selector: (row) => {
        const result = getBestVote(row, players);
        if (result.player) {
          return (
            <span className="voteResult">
              {result.player.last_name} {result.player.first_name}
            </span>
          );
        }
        return (
          <small className="text-muted small" style={{ userSelect: "none" }}>
            non disponible
          </small>
        );
      },
    },
    {
      name: "Type de vote",
      selector: (row) => {
        const type = row.game_id !== null;

        if (type) {
          const game = getModelById(games, row.game_id);
          return (
            <div className="d-flex flex-row justify-content-between align-items-center gap-1">
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  }}
                  src={game.team1.logo}
                  alt={game.team1.name}
                />
                <span>{game.team1.name}</span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div>VS</div>
                <div
                  className="voteResult bg-black"
                  style={{ transform: "scale(.7)" }}
                >
                  {game.score}
                </div>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center">
                <img
                  style={{
                    width: "20px",
                    height: "20px",
                    borderRadius: "10px",
                  }}
                  src={game.team2.logo}
                  alt={game.team2.name}
                />
                <span>{game.team2.name}</span>
              </div>
            </div>
          );
        }
        return <span>Meilleur Joueur</span>;
      },
      sortable: true,
    },
    {
      name: "Joueurs",
      selector: (row) => {
        return <span>{row.players_id.length}</span>;
      },
      sortable: true,
    },
    {
      name: "Votes",
      selector: (row) => {
        return <span>{row.votes.length}</span>;
      },
      sortable: true,
    },
    {
      name: "Début",
      selector: (row) => {
        const type = row.game_id !== null;
        if (type) {
          const game = getModelById(games, row.game_id);
          return <span>{parseReadableDate(new Date(game.start_at))}</span>;
        }
        return <span>{parseReadableDate(new Date(row.interval[0]))}</span>;
      },
      sortable: true,
    },
    {
      name: "Fin",
      selector: (row) => {
        const type = row.game_id !== null;
        if (type) {
          const game = getModelById(games, row.game_id);
          return <span>{parseReadableDate(new Date(game.end_at))}</span>;
        }
        return <span>{parseReadableDate(new Date(row.interval[1]))}</span>;
      },
      sortable: true,
    },
    {
      name: "Actif",
      selector: (row) => {
        return (
          <label className="switch" onClick={(e) => handleActiveVote(e, row)}>
            <input type="checkbox" checked={row.active} />
            <div></div>
          </label>
        );
      },
      sortable: true,
      sortFunction: (a, b) => {
        return parseInt(a.active) - parseInt(b.active);
      },
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div className="d-flex justify-content-between">
            <button
              onClick={() => {
                setNewVotePlayersId(row.players_id.map(Number));
                setToEdit(row);
                setPreviewImageSrc(row.image);
              }}
              title={"Modifier les infos du joueur"}
              style={{
                color: "#f5f5f5",
                backgroundColor: "rgb(0, 238, 161)",
                fontWeight: "600",
              }}
              className="actionBtn ms-2 p-2"
            >
              <i className="fa fa-edit" aria-hidden="true"></i>
            </button>
            <button
              onClick={() => {
                setVoteResult(row);
              }}
              title={"Voir l'évolution du vote"}
              style={{
                color: "#f5f5f5",
                backgroundColor: "rgb(0, 238, 161)",
                fontWeight: "600",
              }}
              className="actionBtn ms-2 p-2"
            >
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </button>
          </div>
        );
      },
    },
  ];

  const [key, setKey] = useState(0);

  return (
    <AdminContainer>
      {!onAdd && !toEdit && !voteResult ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="fw-bold text-light text-uppercase">votes</h4>
            <div className="">
              {userData && userData.admin === 1 && (
                <div className="d-flex gap-2">
                  <button
                    onClick={() => setOnAdd(true)}
                    className="btn border openCart fw-bold text-uppercase"
                  >
                    {" "}
                    <i className="fa fa-plus"></i> ajouter
                  </button>
                  <button
                    onClick={() => setStep(step === 0 ? 1 : 0)}
                    className="btn border openCart fw-bold text-uppercase"
                  >
                    {" "}
                    <i
                      className={step === 0 ? "fa fa-eye" : "fa fa-eye-slash"}
                    ></i>{" "}
                    gérer
                  </button>
                </div>
              )}
            </div>
          </div>
          {step === 0 ? (
            games !== null && votes !== null && players !== null ? (
              <div className="">
                {votes.length > 0 ? (
                  <div className="mt-3 d-flex flex-column w-100 p-0 m-0">
                    {/* <h4 className='fw-bold text-light text-uppercase'>Listes des candidats</h4> */}
                    <TabPane
                      onSelect={(val) => setKey(val)}
                      activeKey={key}
                      label1="Meilleur Joueur"
                      label2="Homme du match"
                      child1={
                        <div className="">
                          <BestPlayer />
                        </div>
                      }
                      child2={
                        <div className="">
                          <ManOfMatch />
                        </div>
                      }
                    />
                  </div>
                ) : (
                  <NoVote />
                )}
              </div>
            ) : (
              <CircularLoader />
            )
          ) : (
            <div className="mt-3">
              <DataTable
                className="PaymentHistory"
                columns={voteDataColumns}
                data={votes}
                striped
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              />
            </div>
          )}
        </>
      ) : (
        !voteResult &&
        (onAdd && !toEdit ? (
          <AddVoteForm
            players={players}
            games={games}
            className={"col-12 col-lg-8"}
            onBack={() => setOnAdd(false)}
          />
        ) : (
          <EditVoteForm
            players={players}
            games={games}
            vote={toEdit}
            className={"col-12 col-lg-8"}
            onBack={() => setToEdit(false)}
          />
        ))
      )}
      {voteResult ? (
        <VoteRanking
          onBack={() => {
            setVoteResult(null);
          }}
          players={players}
          vote={voteResult}
          games={games}
        />
      ) : null}

      {confirm && (
        <Modal
          onClose={(e) => {
            setConfirm(null);
          }}
          open={confirm ?? false}
          title={
            <div className="text-uppercase" style={{ color: "#e89f29" }}>
              <i className="fas fa-exclamation-circle fa-1x"></i>{" "}
              {confirm.title}
            </div>
          }
        >
          {!confirm.onConfirm && (
            <div className="d-flex flex-column justify-content-between p-3">
              <div>{confirm.message}</div>
              <div className="d-flex justify-content-end w-100 mt-3">
                <button
                  style={{ background: "#e89f29 !important" }}
                  className="downloadBtn warning text-uppercase"
                  onClick={(e) => confirm.callback(e)}
                >
                  Confirmer
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </AdminContainer>
  );
};

export default VotePage;
