const baseUri = process.env.REACT_APP_RECRUIT;
// const baseUri = "http://localhost:5500/api";

const getAll = async () => {
  const response = await fetch(`${baseUri}/sessions`);
  const data = await response.json();
  return data;
};

const createSession = async (data) => {
  try {
    const response = await fetch(`${baseUri}/sessions`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw { response: { data: errorData } };
    }

    return await response.json();
  } catch (error) {
    throw error;
  }
};


const getParticipations = async (session) => {
  const response = await fetch(`${baseUri}/payments?session=${session}`);
  const data = response.json();
  return data;
};

const SessionService = {
  getAll,
  createSession,
  getParticipations,
};

export default SessionService;
