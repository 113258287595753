import axios from "axios"
import { formatDate } from "../utils/utils";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = () => {
    return axios.get(`carts`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        // console.log(data.data);
        return data;
    })
}

const getAllAdmin = (interval) => {
    let url = `admin/carts`

    if (interval) {
        const startDate = formatDate(interval[0])
        const endDate = formatDate(interval[1])
        url = `admin/carts?startDate=${startDate}&endDate=${endDate}`
    }
    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const showimpaied = () => {
    return axios.get(`carts/panier`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const addCart = (cart) => {
    return axios.post('carts', cart, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const removeCart = (cart) => {
    return axios.get(`carts/delete/${cart.id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const CartService = {
    getAll,
    getAllAdmin,
    addCart,
    removeCart,
    showimpaied
}

export default CartService;