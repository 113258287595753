// import "./../../styles/Navbar.css";
// import { NavLink } from "react-router-dom";
// import useLanguage from "../../hook/useLanguage";

// const Navbar = () => {
//   const [language, t, handleChangeLanguage] = useLanguage();

//   return (
//     <div className="Navbar d-flex justify-content-between align-items-center">
//       <div className="Navbar__links d-flex justify-content-between align-items-center gap-4">
//         <NavLink to="/login">{t("Login")}</NavLink>
//         <NavLink to="/">
//           <img src="./yafoot-club.png" alt="" />
//         </NavLink>
//         <NavLink to="/register">{t("Register")}</NavLink>
//         <NavLink to="/don">{t("Donnation")}</NavLink>

//       </div>

//       <div className="d-flex justify-content-between align-items-center gap-3">
//         {/* <i className='fa fa-user'></i>
//             <i className='fa fa-search'></i> */}
//         {/* <NavLink to='/donation'>
//                 <button className='btn btn-primary text-uppercase fw-bold'><i className="fas fa-donate me-1"></i>Faire un don</button>
//             </NavLink> */}

//         <div className="language-change d-flex justify-content-between gap-1">
//           <span
//             onClick={() => handleChangeLanguage("fr")}
//             className={
//               "language-change__item language-change__fr" +
//               (language === "fr" ? " language-change__item--active" : "")
//             }
//           >
//             FR
//           </span>
//           <span>|</span>
//           <span
//             onClick={() => handleChangeLanguage("en")}
//             className={
//               "language-change__item language-change__en" +
//               (language === "en" ? " language-change__item--active" : "")
//             }
//           >
//             EN
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Navbar;

import "./../../styles/Navbar.css";
import { NavLink } from "react-router-dom";
import useLanguage from "../../hook/useLanguage";
import MobileMenu from "./MobileMenu";
import { useDispatch } from "react-redux";
import { logoutCurrentUser } from "../../redux/slices/userSlice";



const Navbar = () => {
  const dispatch = useDispatch()
  const [language, t, handleChangeLanguage] = useLanguage();
  const userToken = localStorage.getItem('authToken');

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logoutCurrentUser()).then(() => {
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    });
};
  


  return (

        <div className="Navbar d-flex justify-content-between align-items-center">
          <div className="Navbar__links d-flex justify-content-between align-items-center gap-4">
            
            <NavLink to="/">
              <img src="./yafoot-club.png" alt="" />
            </NavLink>

          </div>

          <div className="Navbar__links d-none d-lg-flex justify-content-between align-items-center gap-4">
            {/* <NavLink to="/login" className="mobile__link" >{t("Login")}</NavLink>

            <NavLink to="/register" className="mobile__link">{t("Register")}</NavLink> */}

            {!userToken ? (
                    <>
                        <NavLink to="/login" className="mobile__link" >{t("Login")}</NavLink>

                        <NavLink to="/register" className="mobile__link">{t("Register")}</NavLink>
                    </>
                ):(
                    <>
                        <NavLink to="/card" className="mobile__link">{t("Dashboard")}</NavLink>
                        <div className='mobile__link' onClick={()=>{handleLogout()}} style={{cursor: "pointer"}}>{t('Logout')}</div>
                    </>
                )}

              <NavLink to="/don" className="mobile__link">{t("Donation")}</NavLink>
          </div>

          {/* language toggler */}
          <div className="language-change d-none d-lg-flex justify-content-between gap-1">
              <span
                onClick={() => handleChangeLanguage("fr")}
                className={
                  "language-change__item language-change__fr" +
                  (language === "fr" ? " language-change__item--active" : "")
                }
              >
                FR
              </span>
              <span>|</span>
              <span
                onClick={() => handleChangeLanguage("en")}
                className={
                  "language-change__item language-change__en" +
                  (language === "en" ? " language-change__item--active" : "")
                }
              >
                EN
              </span>
          </div>

          <div className="d-lg-none">
            <MobileMenu />
          </div>
      </div>

  );
};

export default Navbar;
