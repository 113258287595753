/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import AdminContainer from "../components/AdminContainer";
import RevenuCard from "../components/card/RevenuCard";
import PulseLoader from "../components/loader/PulseLoader";
import useLanguage from "../hook/useLanguage";
import { getCards } from "../redux/slices/cardSlice";
import { getCommandsAdmin } from "../redux/slices/commandSlice";
import { getItems } from "../redux/slices/itemSlice";
import { getMembers } from "../redux/slices/memberSlice";
import { getPaymentsAdmin } from "../redux/slices/paymentSlice";
import {
  getPrintRequests,
  getPrintRequestsAdmin,
} from "../redux/slices/printRequestSlice";
import { getSubscriptionsAdmin } from "../redux/slices/subscriptionSlice";
import {
  formatNumber,
  formatPrice,
  getCommandPayment,
  getDonationPayment,
  getModelById,
  getPrintRequestPayment,
  getSubscriptionPayment,
  nFormatter,
} from "../utils/utils";
import "./../styles/DashboardPage.css";
import "./../styles/IncomePage.css";
import { getDonations } from "../redux/slices/donationSlice";
import { PAYMENT_STATUS } from "../utils/constants";

const IncomePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [language, t, handleChangeLanguage] = useLanguage();
  const userData = useSelector((state) => state.user);
  const memberData = useSelector((state) => state.member);
  const payments = useSelector((state) => state.payment);
  const subscriptions = useSelector((state) => state.subscription);
  const orders = useSelector((state) => state.command);
  const items = useSelector((state) => state.item);
  const cards = useSelector((state) => state.card);
  const printRequests = useSelector((state) => state.printRequest);
  const donations = useSelector((state) => state.donation);
  const [dateRange, setDateRange] = useState(null);

  useEffect(() => {
    dispatch(getMembers({ reset: false, interval: null }));
    if (!cards) dispatch(getCards());
    if (!payments)
      dispatch(getPaymentsAdmin({ reset: false, interval: dateRange }));
    if (!subscriptions)
      dispatch(getSubscriptionsAdmin({ reset: false, interval: dateRange }));
    if (!orders)
      dispatch(getCommandsAdmin({ reset: false, interval: dateRange }));
    if (!items) dispatch(getItems({ reset: false, interval: dateRange }));
    if (!printRequests)
      dispatch(getPrintRequestsAdmin({ reset: false, interval: dateRange }));
    if (!donations)
      dispatch(getDonations({ reset: false, interval: dateRange }));
    setDateRange([new Date(), new Date()]);
  }, []);

  const invalidateAndRefetchData = () => {
    setData({
      soldedCardNumber: "...",
      soldedCardPrice: "...",
      soldedCommandNumber: "...",
      soldedCommandPrice: "...",
      soldedPrintNumber: "...",
      soldedPrintPrice: "...",
      memberNumber: "...",
      donationNumber: "...",
      donationPrice: "...",
    });
    dispatch(getCards());
    dispatch(getPaymentsAdmin({ reset: true, interval: dateRange }));
    dispatch(getPrintRequests({ reset: true, interval: dateRange }));
    dispatch(getMembers({ reset: true, interval: dateRange }));
    dispatch(getSubscriptionsAdmin({ reset: true, interval: dateRange }));
    dispatch(getDonations({ reset: true, interval: dateRange }));

    dispatch(getCards());
    dispatch(getPaymentsAdmin({ reset: false, interval: null }));
    dispatch(getPrintRequestsAdmin({ reset: false, interval: null }));
    dispatch(getMembers({ reset: false, interval: null }));
    dispatch(getSubscriptionsAdmin({ reset: false, interval: null }));
    dispatch(getDonations({ reset: false, interval: null }));
  };

  const [data, setData] = useState({
    soldedCardNumber: "...",
    soldedCardPrice: "...",
    soldedCommandNumber: "...",
    soldedCommandPrice: "...",
    soldedPrintNumber: "...",
    soldedPrintPrice: "...",
    memberNumber: "...",
    donationNumber: "...",
  });

  const getSoldedCard = useCallback((subs, _filter = null) => {
    let output = subs.filter((value, index) => {
      let card = getModelById(cards, value.card_id);
      let payment = getSubscriptionPayment(value.id, payments);
      if (!payment) return false;
      if (_filter) {
        return (
          value.subscription_id !== null &&
          card?.type === _filter &&
          payment.status.includes(PAYMENT_STATUS.RECEIVED)
        );
      } else {
        return (
          value.subscription_id !== null && payment.status.includes(PAYMENT_STATUS.RECEIVED)
        );
      }
    });
    return output;
  });

  const getSoldedCardPrice = (subs, filter = null) => {
    subs = getSoldedCard(subs, filter);
    let amount = {
      cc: 0,
      mobile: 0,
    };
    subs.forEach((val, key) => {
      let card = getModelById(cards, val.card_id);
      let payment = getSubscriptionPayment(val.id, payments);
      if (filter) {
        if (card?.type === filter) {
          if (payment.payment_method === "cc") {
            // credit card payment
            amount = {
              ...amount,
              cc: amount.cc + parseFloat(card?.card_price),
            };
          } else {
            // mobile payment
            amount = {
              ...amount,
              mobile: amount.mobile + parseFloat(card?.card_price),
            };
          }
        }
      } else {
        if (payment.payment_method === "cc") {
          // credit card payment
          amount = {
            ...amount,
            cc: amount.cc + parseFloat(card?.card_price),
          };
        } else {
          // mobile payment
          amount = {
            ...amount,
            mobile: amount.mobile + parseFloat(card?.card_price),
          };
        }
      }
    });
    return amount;
  };

  const getSoldedPrint = (prints, _filter = null) => {
    let output = prints.filter((value, index) => {
      let subscription = getModelById(subscriptions, value.subscription_id);
      if (subscription) {
        let payment = getSubscriptionPayment(subscription.id, payments);
        let card = getModelById(cards, subscription.card_id);
        if (_filter) {
          return card?.type === _filter && payment.status.includes(PAYMENT_STATUS.RECEIVED);
        } else {
          return payment.status.includes(PAYMENT_STATUS.RECEIVED);
        }
      } else {
        return false;
      }
    });
    return output;
  };

  useEffect(() => {
    const getSoldedDonation = (donations) => {
      let amount = {
        cc: 0,
        mobile: 0,
      };

      donations.forEach((donation) => {
        let payment = getDonationPayment(donation.id, payments);
        if (!payment || payment.status !== PAYMENT_STATUS.RECEIVED) return;
        if (payment.payment_method === "cc") {
          // credit card payment
          amount = { ...amount, cc: amount.cc + parseFloat(donation.amount) };
        } else {
          // mobile payment
          amount = {
            ...amount,
            mobile: amount.mobile + parseFloat(donation.amount),
          };
        }
      });

      return amount;
    };

    const getSoldedCommandPrice = (commands, filter = null) => {
      let amount = {
        cc: 0,
        mobile: 0,
      };
      commands.forEach((command) => {
        let payment = getCommandPayment(command.id, payments);
        if (!payment) return;
        if (payment.payment_method === "cc") {
          // credit card payment
          amount = { ...amount, cc: amount.cc + parseFloat(command.price) };
        } else {
          // mobile payment
          amount = {
            ...amount,
            mobile: amount.mobile + parseFloat(command.price),
          };
        }
      });
      return amount;
    };

    const getSoldedPrintPrice = (prints, filter = null) => {
      prints = getSoldedPrint(prints, filter);
      let amount = {
        cc: 0,
        mobile: 0,
      };

      prints.forEach((val, key) => {
        let subscription = getModelById(subscriptions, val.subscription_id);
        let payment = getPrintRequestPayment(val.id, payments);
        let card = getModelById(cards, subscription.card_id);
        if (!payment) return;
        if (filter) {
          if (card?.type === filter) {
            if (payment.payment_method === "cc") {
              // credit card payment
              amount = {
                ...amount,
                cc: amount.cc + parseFloat(card.print_price),
              };
            } else {
              // mobile payment
              amount = {
                ...amount,
                mobile: amount.mobile + parseFloat(card.print_price),
              };
            }
          }
        } else {
          if (payment.payment_method === "cc") {
            // credit card payment
            amount = {
              ...amount,
              cc: amount.cc + parseFloat(card.print_price),
            };
          } else {
            // mobile payment
            amount = {
              ...amount,
              mobile: amount.mobile + parseFloat(card.print_price),
            };
          }
        }
      });
      return amount;
    };

    if (
      subscriptions &&
      cards &&
      payments &&
      orders &&
      printRequests &&
      memberData &&
      donations
    ) {
      setData({
        ...data,
        soldedCardNumber: formatNumber(getSoldedCard(subscriptions).length),
        soldedCardPrice: getSoldedCardPrice(subscriptions),
        soldedPrintNumber: formatNumber(getSoldedPrint(printRequests).length),
        soldedPrintPrice: getSoldedPrintPrice(printRequests),
        memberNumber: formatNumber(memberData.length),
        donationPrice: getSoldedDonation(donations),
        donationNumber: nFormatter(getSoldedDonation(donations)),
        soldedCommandPrice: getSoldedCommandPrice(orders),
      });
    }
  }, [
    subscriptions,
    payments,
    cards,
    printRequests,
    donations,
    orders,
    memberData,
    data,
    getSoldedCard,
  ]);

  return (
    <AdminContainer>
      <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center">
        <h4 className="fw-bold text-light text-uppercase">
          VOTRE TABLEAU DE BORD
        </h4>
        <div className="d-flex gap-3 ms-auto">
          <div className="refreshVote d-flex justify-content-end align-items-center">
            <div
              className="rounded p-2"
              onClick={(e) => invalidateAndRefetchData()}
            >
              <i className="fa fa-sync"></i> actualiser
            </div>
          </div>
          {/* <DateRangePicker disableCalendar={false} className='btn reloadBtn' onChange={setDateRange} value={dateRange} style={{ zIndex: '1 !important' }} /> */}
        </div>
      </div>

      <div className="cardsContainer my-3 d-flex gap-3 justify-content-center p-0 m-0">
        <RevenuCard
          value={data.soldedCardNumber}
          label={
            <span>
              cartes YAFOOT
              <br />
              vendues
            </span>
          }
          icon={<i className="bi bi-person-vcard-fill fa-5x text-light"></i>}
        >
          {printRequests !== null &&
          subscriptions !== null &&
          payments !== null &&
          orders !== null &&
          cards !== null ? (
            <div className="d-flex gap-3 justify-content-between align-items-center">
              {cards.forEach((card) => {
                const total = getSoldedCard(subscriptions, card?.type)?.length;
                if (total > -1) {
                  return (
                    <div
                      key={card.id}
                      className="tinnycards d-flex flex-column justify-content-center align-items-center rounded small text-muted p-1"
                    >
                      <span>{card?.type}</span>
                      <span>{total}</span>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <PulseLoader />
          )}
        </RevenuCard>
        <RevenuCard
          value={nFormatter(
            data.soldedCardPrice.cc + data.soldedCardPrice.mobile,
            2
          )}
          label={
            <span>
              revenues des cartes
              <br />
              YAFOOT
            </span>
          }
          icon={<i className="bi bi-cash-coin fa-5x text-light"></i>}
        >
          {subscriptions !== null &&
          cards !== null &&
          payments !== null &&
          printRequests !== null ? (
            <div className="d-flex flex-column">
              <div className="d-flex gap-3 text-white-50">
                <div>CC: {nFormatter(data.soldedCardPrice.cc, 2)}</div>
                <div>MOBILE: {nFormatter(data.soldedCardPrice.mobile, 2)}</div>
              </div>
              <div className="d-flex gap-3 justify-content-between align-items-center">
                {cards.forEach((card) => {
                  let total = getSoldedCardPrice(subscriptions, card?.type);
                  total = total.cc + total.mobile;
                  if (total > -1) {
                    return (
                      <div
                        key={card.id}
                        className="tinnycards d-flex flex-column justify-content-center align-items-center rounded small text-muted p-1"
                      >
                        <span>{card?.type}</span>
                        <span>{formatPrice(total)}</span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            <PulseLoader />
          )}
        </RevenuCard>
      </div>

      <div className="cardsContainer my-3 d-flex gap-3 justify-content-center p-0 m-0">
        <RevenuCard
          value={nFormatter(
            data.donationPrice?.cc + data.donationPrice?.mobile,
            2
          )}
          label={
            <span>
              revenus des
              <br />
              dons
            </span>
          }
          icon={<i className="fas fa-hand-holding-usd fa-5x text-light"></i>}
        >
          {subscriptions !== null &&
          cards !== null &&
          payments !== null &&
          printRequests !== null &&
          donations !== null &&
          data.donationPrice ? (
            <div className="d-flex flex-column">
              <div className="d-flex gap-3 text-white-50">
                <div>CC: {nFormatter(data.donationPrice.cc, 2)}</div>
                <div>MOBILE: {nFormatter(data.donationPrice.mobile, 2)}</div>
              </div>
            </div>
          ) : (
            <PulseLoader />
          )}
        </RevenuCard>
        <RevenuCard
          value={nFormatter(
            data.soldedCommandPrice.cc + data.soldedCommandPrice.mobile,
            2
          )}
          label={
            <span>
              revenus des
              <br />
              commandes
            </span>
          }
          icon={<i className="fa fa-dollar fa-5x text-light"></i>}
        >
          {subscriptions !== null &&
          cards !== null &&
          payments !== null &&
          printRequests !== null &&
          orders !== null ? (
            <div className="d-flex flex-column">
              <div className="d-flex gap-3 text-white-50">
                <div>CC: {nFormatter(data.soldedCommandPrice.cc, 2)}</div>
                <div>
                  MOBILE: {nFormatter(data.soldedCommandPrice.mobile, 2)}
                </div>
              </div>
            </div>
          ) : (
            <PulseLoader />
          )}
        </RevenuCard>
      </div>

      <div className="cardsContainer mt-4 d-flex gap-3 justify-content-center p-0 m-0">
        <RevenuCard
          value={nFormatter(
            data.soldedPrintPrice.cc + data.soldedPrintPrice.mobile,
            2
          )}
          label={
            <span>
              revenues des
              <br />
              impressions
            </span>
          }
          icon={<i className="fa fa-print fa-5x text-light"></i>}
        >
          {subscriptions !== null &&
          cards !== null &&
          payments !== null &&
          printRequests !== null ? (
            <div className="d-flex flex-column">
              <div className="d-flex gap-3 text-white-50">
                <div>CC: {nFormatter(data.soldedPrintPrice.cc, 2)}</div>
                <div>MOBILE: {nFormatter(data.soldedPrintPrice.mobile, 2)}</div>
              </div>
              <div className="d-flex gap-3 justify-content-between align-items-center">
                {cards.forEach((card) => {
                  const total = getSoldedPrint(
                    printRequests,
                    card?.type
                  )?.length;
                  if (total > -1) {
                    return (
                      <div
                        key={card.id}
                        className="tinnycards d-flex flex-column justify-content-center align-items-center rounded small text-muted p-1"
                      >
                        <span>{card?.type}</span>
                        <span>{total}</span>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          ) : (
            <PulseLoader />
          )}
        </RevenuCard>
        <RevenuCard
          value={data.memberNumber}
          label={
            <span>
              membre de
              <br />
              YAFOOT
            </span>
          }
          icon={<i className="fa fa-user fa-5x text-light"></i>}
        >
          {subscriptions !== null &&
          memberData &&
          cards !== null &&
          payments !== null &&
          printRequests !== null ? (
            <div className="d-flex gap-3 justify-content-between align-items-center">
              {["non actif", "actif"].map((status, key) => {
                let mbs = memberData.filter((member) =>
                  (member.active + "").includes(key)
                );
                return (
                  <div
                    key={key}
                    className="tinnycards d-flex flex-column justify-content-center align-items-center rounded small text-muted p-1"
                  >
                    {status}
                    <br />
                    {mbs?.length}
                  </div>
                );
              })}
            </div>
          ) : (
            <PulseLoader />
          )}
        </RevenuCard>
      </div>
    </AdminContainer>
  );
};

export default IncomePage;
