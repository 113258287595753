import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {GameService} from "../../services/GameService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = [];

export const getGames = createAsyncThunk(
  "game/all",
  async () => {
    const res = await GameService.all();
    //console.log(res.data);
    return res.data;
  }
);

export const addGame = createAsyncThunk(
  "game/add",
  async (player) => {
    const res = await GameService.add(player);
    return res.data;
  }
);

export const updateGame = createAsyncThunk(
  "game/update",
  async (game) => {
    const res = await GameService.update(game);
    return res.data;
  }
);

const gameSlice = createSlice({
  name: "game",
  initialState,
  extraReducers: {
    [getGames.fulfilled]: (state, action) => {
      return action.payload
    },
    [addGame.fulfilled]: (state, action) => {
      if (state) {
        state = [...state, action.payload.data]
      } else {
        state = [action.payload.data]
      }
      return state
    },
    [updateGame.fulfilled]: (state, action) => {
      const index = state.findIndex(game => game.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
      return state
    },

  },
});

const { reducer } = gameSlice;
export default reducer;