import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import SeasonService from "../../services/SeasonService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getSeasons = createAsyncThunk(
  "season/all",
  async () => {
    const res = await SeasonService.getAll();
    // console.log('[season]', res.data);
    return res.data;
  }
);


const seasonSlice = createSlice({
  name: "season",
  initialState,
  extraReducers: {
    [getSeasons.fulfilled]: (state, action) => {
        return action.payload
    },
  },
});

const { reducer } = seasonSlice;
export default reducer;