/* eslint-disable no-unused-vars */
import { useState } from "react"

const ImageViewer = ({ onClose, image, season }) => {
    const [onDownload, setOnDownload] = useState(false);

    return <div onClick={onClose} className="contaienr ImageViewer d-flex align-items-center justify-content-center">
        <div className="tilt-box-wrap d-flex align-items-center flex-column justify-content-center">
            <span className="t_over"></span>
            <span className="t_over"></span>
            <span className="t_over"></span>
            <span className="t_over"></span>
            <span className="t_over"></span>
            <span className="t_over"></span>
            <span className="t_over"></span>
            <span className="t_over"></span>
            <span className="t_over"></span>
            <div style={{overflow: 'hidden', minHeight:'100vh', minWidth:'100vw'}} className="tilt-box d-flex justify-content-center border-0 align-items-center gap-2">
                <img src={image} className="img-fluid w-25" alt={""} /><br />
                {season && <img src={season} className="img-fluid w-25" alt={""} />}
            </div>
        </div>
    </div>
}

export default ImageViewer;