/* eslint-disable no-loop-func */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCards } from "../../redux/slices/cardSlice";
import { getGames } from "../../redux/slices/gameSlice";
import { getPayments } from "../../redux/slices/paymentSlice";
import { getPlayers } from "../../redux/slices/playerSlice";
import { getSeasons } from "../../redux/slices/seasonSlice";
import { getSubscriptions } from "../../redux/slices/subscriptionSlice";
import {
  // addVote,
  getVotes,
  makeVote,
} from "../../redux/slices/voteSlice";
import {
  formatPrice,
  getModelById,
  getOnlyOneName,
  parseReadableDate,
} from "../../utils/utils";
import CircularLoader from "../loader/CircularLoader";
import NoVote from "./NoVote";
import "./styles/BestPlayer.css";

export default function ManOfMatch() {
  const dispatch = useDispatch();
  // const voteData = useSelector((state) => state.vote);
  // const playerData = useSelector((state) => state.player);
  const paymentData = useSelector((state) => state.payment);
  const seasonData = useSelector((state) => state.season);
  const gameData = useSelector((state) => state.game);
  const cardData = useSelector((state) => state.card);
  const subscriptionData = useSelector((state) => state.subscription);
  const userData = useSelector((state) => state.user);
  const [bestPlayerVote, setBestPlayerVote] = useState(null);
  const [ranking, setRanking] = useState(null);
  const [q, setQ] = useState(null);
  const [gameVote, setGameVote] = useState(null);

  const voteDateHasPassed = (endDate) => {
    return new Date() > endDate;
  };

  const getPlayerVoteResult = (voteData, playerId) => {
    let voting = 0;
    voteData.votes.forEach((vote) => {
      if (vote[1] === playerId) {
        voting++;
      }
    });
    return voting;
  };

  const orderResultByVoting = (participants) => {
    const maxLenght = participants.length;
    var bestVoting = getMaxResult(participants);
    let output = [bestVoting];
    participants = participants.filter((p) => p.id !== bestVoting.id);
    while (output.length !== maxLenght) {
      bestVoting = getMaxResult(participants);
      if (bestVoting === null) {
        break;
      }
      output.push(bestVoting);
      participants = participants.filter((p) => p.id !== bestVoting.id);
    }
    return output;
  };

  const getMaxResult = (result) => {
    var best = { player: null, voting: 0 };
    result.forEach((r) => {
      if (r.voting >= best.voting) {
        best = r;
      }
    });

    return best;
  };

  const getUserVote = (votesData, userData) => {
    let userVotes = [];
    votesData.votes.forEach((vote) => {
      if (vote[0] === userData.id) {
        userVotes.push(vote);
      }
    });
    return userVotes;
  };

  const userHasVoted = (votesData, userDate) =>
    getUserVote(votesData, userDate).length !== 0;

  const userHasVotedPlayer = (votesData, userDate, playerId) => {
    const vote = getUserVote(votesData, userDate);
    let voted = false;
    if (vote.length !== 0) {
      vote.forEach((v) => {
        if (v[1] === playerId) {
          voted = true;
        }
      });
    }
    return voted;
  };
  const [hasVote, setHasVote] = useState(true);
  const reloadVote = async () => {
    // setRanking(null)
    await dispatch(getPlayers()).then((data) => {
      // console.log(data);
      const _players = data.payload;
      dispatch(getVotes()).then((data) => {
        const playerVote = data.payload.filter((vote) => vote.game_id !== null);
        if (playerVote.length > 0) {
          const _bestPlayerVote = playerVote[0];
          setBestPlayerVote(_bestPlayerVote);
          setGameVote(getModelById(gameData, _bestPlayerVote.game_id));
          let participants = _bestPlayerVote.players_id.map((id) =>
            getModelById(_players, id)
          );

          participants = participants.map((participant) => {
            return {
              ...participant,
              voting: getPlayerVoteResult(_bestPlayerVote, participant.id),
            };
          });
          setRanking(orderResultByVoting(participants));
        } else {
          setHasVote(false);
        }
      });
    });
  };

  const handleReload = async (e) => {
    e.target.innerHTML = "actualisation...";
    await reloadVote()
      .then(() => {})
      .finally(() => {
        e.target.innerHTML = "<i className='fa fa-sync'></i> actualiser";
      });
    e.target.innerHTML = "<i className='fa fa-sync'></i> actualiser";
  };

  useEffect(() => {
    reloadVote();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  useEffect(() => {
    !paymentData && dispatch(getPayments());
    !seasonData && dispatch(getSeasons());
    !cardData && dispatch(getCards());
    !subscriptionData && dispatch(getSubscriptions());
    !gameData && dispatch(getGames());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentData, seasonData, cardData]);

  const filterByName = (playersToFilter, search) => {
    if (!(search !== null && search.length > 0)) return playersToFilter;
    search = search.toLowerCase();
    return playersToFilter.filter((p, key) => {
      return (
        p.last_name.toLowerCase().includes(search) ||
        p.first_name.toLowerCase().includes(search)
      );
    });
  };

  const [onVote, setOnVote] = useState(false);

  const handleAddVote = (e, player) => {
    e.preventDefault();
    e.stopPropagation();
    const minimumPayPrice = 5000;
    if (!userSatisfiedMinimumPayments(minimumPayPrice)) {
      return toast.warn(`Désolé vous devez payer 
           au minimum une carte de ${formatPrice(minimumPayPrice)} pour
           pouvoir voter.`);
    }
    if (voteDateHasPassed(new Date(bestPlayerVote.interval[1]))) {
      return toast.warn("La période de vote est terminée !");
    }
    if (!userHasVoted(bestPlayerVote, userData)) {
      if (!onVote) {
        e.target.innerHTML = "vote en cours...";
        e.target.setAttribute("disabled", true);
        setOnVote(true);
        dispatch(makeVote({ voteId: bestPlayerVote.id, playerId: player.id }))
          .then(() => {
            toast.success("votre vote a été pris en compte.");
            reloadVote();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            e.target.innerHTML = "je vote";
            e.target.removeAttribute("disabled");
            setOnVote(false);
          });
      } else {
        toast.warn("Un vote est en cours, veuillez patienter.");
      }
    } else {
      toast.warn("vous avez déjà voté un joueur !");
    }
  };

  const userSatisfiedMinimumPayments = (value = 5000) => {
    let userSatisfied = false;
    const lastSeason = seasonData.at(-1);
    // check whether user has pay card that price is greatherl
    // or equal then ${value}
    paymentData.forEach((payment) => {
      const subscription = getModelById(
        subscriptionData,
        payment.subscription_id
      );
      if (subscription) {
        const card = getModelById(cardData, parseInt(subscription.card_id));
        if (
          parseInt(subscription.season_id) === parseInt(lastSeason.id) &&
          parseInt(card?.card_price) >= value
        ) {
          userSatisfied = true;
        }
      }
    });
    return userSatisfied;
  };

  return (
    <>
      {ranking && (
        <div className=" " style={{ minHeight: "100vh" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="fw-bold text-uppercase me-2">
              <div className="d-flex flex-row justify-content-between align-items-center gap-1">
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    src={gameVote.team1.logo}
                    alt={gameVote.team1.name}
                  />
                  <span className="text-light fw-light">
                    {gameVote.team1.name}
                  </span>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div className="text-white">VS</div>
                  <div
                    className="voteResult bg-black"
                    style={{ transform: "scale(.7)" }}
                  >
                    {gameVote.score}
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <img
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                    }}
                    src={gameVote.team2.logo}
                    alt={gameVote.team2.name}
                  />
                  <span className="text-light fw-light">
                    {gameVote.team2.name}
                  </span>
                </div>
              </div>
            </div>
            <div className="text-light fw-bold text-uppercase">
              Date limite : {parseReadableDate(bestPlayerVote.interval[1])}
            </div>
          </div>
          <div className="w-75 d-flex justify-content-start mt-1">
            <input
              placeholder="Recherchez un joueur"
              onChange={(e) => setQ(e.target.value)}
              className="w-75 searchBar bg-transparent form-control mr-sm-2"
              type="search"
              aria-label="Search"
            />
          </div>
          <div className="refreshVote d-flex justify-content-end">
            <div className="rounded p-1" onClick={(e) => handleReload(e)}>
              <i className="fa fa-sync"></i> actualiser
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="bestPlayerList w-100 mt-2 border rounded">
              {voteDateHasPassed(new Date(bestPlayerVote.interval[1])) && (
                <div className="w-100 d-flex align-items-center justify-content-center">
                  <div className="bestPlayerListItem manOfTheMatch col-10 col-md-4 shadow">
                    {bestPlayerVote.votes.length > 0 && (
                      <div className="ranking p-2 pt-0 text-uppercase fw-bold text-dark ribbon">
                        {" "}
                        <i className="fa fa-futbol mx-2"></i>Meilleur Joueur
                      </div>
                    )}
                    <div className="d-flex flex-column justify-content-center">
                      <img src={ranking[0].image} alt={ranking[0].last_name} />
                      <div className="d-flex gap-2 justify-content-center text-dark fw-bold">
                        <span className="d-inline-block text-truncate text-light">
                          {getOnlyOneName(ranking[0].last_name)}{" "}
                          {getOnlyOneName(ranking[0].first_name)}
                        </span>
                      </div>
                      <span className="voteResult w-25 justify-content-center align-items-center align-self-center">
                        {ranking[0].voting}{" "}
                        {ranking[0].voting > 1 ? "votes" : "vote"}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {filterByName(ranking, q).length === 0 && (
                <div className="text-muted fs-5"> Aucun Résulat </div>
              )}
              {filterByName(ranking, q).map((player, key) => {
                return (
                  <div key={player.id} className="bestPlayerListItem">
                    {bestPlayerVote.votes.length > 0 && (
                      <div className="ranking">
                        <div className="shadow">#{key + 1}</div>
                      </div>
                    )}
                    <div className="content border">
                      <img src={player.image} alt={player.last_name} />
                      <div className="content-name">
                        <span className="d-inline-block text-truncate">
                          {getOnlyOneName(player.last_name)}{" "}
                          {getOnlyOneName(player.first_name)}
                        </span>
                      </div>
                      <div className="content-voting">
                        <div>
                          {player.voting} {player.voting > 1 ? "votes" : "vote"}
                        </div>
                      </div>
                    </div>
                    <div className="content-action w-100">
                      <div className="w-100">
                        <button
                          className={
                            "w-100 " +
                            (userHasVotedPlayer(
                              bestPlayerVote,
                              userData,
                              player.id
                            )
                              ? " active"
                              : " ")
                          }
                          onClick={(e) => {
                            handleAddVote(e, player);
                          }}
                        >
                          {userHasVotedPlayer(
                            bestPlayerVote,
                            userData,
                            player.id
                          ) ? (
                            <>
                              <i className="fa fa-check-circle"></i> voté
                            </>
                          ) : (
                            "je vote"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {!ranking && (hasVote ? <CircularLoader /> : <NoVote />)}
    </>
  );
}
