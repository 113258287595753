import './styles/ImageCarousel.css'
// import "./Carousel.css";
import { useState } from "react";
import Slider from "react-slick";


function App({items}) {
    //console.log(items);


  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick} style={{background: 'none'}}>
        <div  className='navigationItemBtn d-flex justify-content-center shadow align-items-center'><i className="fa fa-arrow-right" aria-hidden="true"></i></div>
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick} style={{background: 'none'}}>
        <div className='navigationItemBtn d-flex justify-content-center shadow align-items-center'><i className="fa fa-arrow-left" aria-hidden="true"></i></div>
      </div>
    );
  };

  const [imageIndex, setImageIndex] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 350,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setImageIndex(next),
  };

  
  return (
    <div className="App  align-items-center justify-content-center">
      <Slider {...settings} className="slider" >
        {items[0].map((item, idx) => (
          <div className={  idx === imageIndex ? "slide activeSlide " : "slide " }>
            <div className="row align-items-center">
              <div className="col">
                
              </div>
              <div className="col-sm-6 item">
                <img className=" " src={item.image} alt={item.image} />
                <div className="nom text-center mt-2" >
                  {item.name}
                </div>
                <div className="prix text-center mt-2" >
                  {item.price} FCFA
                </div>
                <div className="description text-center" >
                  {item.description}
                </div>
              <div className="text-center border border-radius compt">
                x{items[1][idx].quantity}
              </div>
              </div>
              <div className="col">

              </div>
            </div>
          </div>


        ))}
      </Slider>
    </div>
  );
}

export default App;
