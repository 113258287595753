import { forwardRef } from 'react';
import './../../styles/ShopCart.css';
import CartItem from './CartItem';

const ShopCart = (props, ref) => {
    const { carts, onRemove } = props;

    const handleCloseCart = () => {
        ref.current.classList.remove('open')
    }


    return <div ref={ref} className="ShopCart open d-flex flex-column justify-content-between shadow">
        <div className='ShopCart__close p-2'>
            <i onClick={handleCloseCart} className='fa fa-times'></i>
        </div>
        <div className='CartItemContainer p-2 d-flex flex-column gap-2'>
            {carts.map((cart) => {
                return <CartItem key={cart.id} onRemove={onRemove} cart={cart} />
            })}
        </div>
        <div className='p-2'>
            <button onClick={props.onPay} className='payButton btn w-100 fw-bold text-uppercase'><i className='fa fa-shopping-cart me-1'></i>  ACHETER</button>
        </div>
    </div>
}

export default forwardRef(ShopCart);