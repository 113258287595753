
import './../../styles/CardData.css'

const CardData = ({ icon, value, label }) => {

    return <div className='CardData p-4 gap-2 me-3 d-flex justify-content-start align-items-center'>
        <div className='CardData__image'>
            {icon}
        </div>
        <div className='d-flex flex-column me-2 p-4 justify-content-center align-items-center'>
            <div className='CardData__value'>{value}</div>
            <div className='CardData__label fw-bold text-light text-uppercase'>{label}</div>
        </div>
    </div>
}

export default CardData;