/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber } from 'react-phone-number-input';
import { NavLink, useNavigate } from 'react-router-dom';
import useForm from '../../hook/useForm';
import useLanguage from '../../hook/useLanguage';
import usePasswordVisibility from '../../hook/usePasswordVisibility';
import { UserService } from '../../services/UserService';
import { authFormValidator } from '../../utils/utils';
import Alert from '../Alert';
import './../styles/LoginForm.css';


const LoginForm = () => {
    const [passwordVisibility, handleTogglePasswordVisibility] = usePasswordVisibility(true);
    const navigate = useNavigate();
    const [language, t, handleChangeLanguage] = useLanguage();
    const [message, setMessage] = useState(null)

    const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
        // { id: 'nguetchaalex@gmail.com', password: 'password' },
        { id: '', password: '' },
        authFormValidator
    );

    const __handleSubmit = async (e) => {

        let phoneNumber = "";

        if (formValues.id.indexOf('@') < 0 && formValues.id.length === 9 && formValues.id[0] === "6") {
            phoneNumber = parsePhoneNumber(formValues.id, 'CM').number;
        } else {
            phoneNumber = formValues.id;
        }

        const isSubmittable = handleSubmit(e);
        if (true) {
            e.preventDefault();
            e.stopPropagation();
            e.target.setAttribute("disabled", 'true');
            e.target.innerHTML = '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';
            let data;
            if (isValidPhoneNumber(phoneNumber)) {
                data = { phone: phoneNumber, password: formValues.password }
            } else {
                data = { phone: formValues.id, password: formValues.password }
            }

            UserService.signin(data)
                .then((json) => {

                    if (json.data.errors) {
                        setErrors(json.data.errors)
                    } else {
                        new Promise((resolve, reject) => {
                            localStorage.clear();
                            localStorage.setItem("authToken", json.data.token);
                            localStorage.setItem("authTokenDate", new Date());
                            resolve(localStorage.getItem('authToken'));
                        }).then((token) => {
                            setMessage(t("Login successfully redirection..."));
                            setTimeout(() => { window.location.href = '/card' }, 1500);
                        }); // navigate to user account
                    }
                }).catch((err) => {
                   /*  const data = err.response.data
                    if (data.errors) {
                        setErrors(data.errors);
                    } */
                   console.log(err);
                   
                }).finally(() => {
                    e.target.removeAttribute("disabled");
                    e.target.innerHTML = t('Login');
                });
        }
    }


    return (
        <div className="LoginForm shadow">
            <form className='p-2 m-2 py-4'>
                <h3 className='mb-4'>{t('Login')}</h3>
                <Alert message={message} type={"success"} />
                {errors.length > 0 && <div className='mb-3'>
                    <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {t(errors[0])}</span>
                </div>}
                <div className="mb-3">
                    <input value={formValues.id} onChange={handleChange} type="text"
                        className="form-control" name="id" placeholder={t('Telephone or e-mail address')} />
                </div>
                <div className="input-group mb-3">
                    <input value={formValues.password} onChange={handleChange} type={!passwordVisibility ? "text" : "password"}
                        className="form-control" name="password" placeholder={t('password')} />
                    <span className="input-group-text" id="togglePasswordVisibility"><i onClick={handleTogglePasswordVisibility} className='fa fa-eye'></i></span>
                </div>
                <div className='d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-around justify-content-lg-between gap-3 gap-lg-5 w-100'>
                    <div className='d-inline-flex gap-2'>
                        <div className='gap-2 d-flex'><input style={{ width: '15px' }} type="checkbox" id="remember" value="remember" /></div>
                        <label htmlFor='remember' className='ms-1'>{t('Remember me')}</label>
                    </div>
                    <NavLink className='ms-auto' to="/forgot-password">{t('Forgot your password ?')}</NavLink>
                </div>
                <button onClick={__handleSubmit} type='submit' className='btn btn-primary my-3 mt-4 w-100'>{t('Login')}</button>
            </form>
        </div>


        // <div className="LoginForm shadow">
        //     <form className='p-2 m-2 py-4' onSubmit={__handleSubmit}>
        //         <h3 className='mb-4'>{t('Login')}</h3>
        //         <Alert message={message} type={"success"} />
        //         {errors.length > 0 && <div className='mb-3'>
        //             <span className='form-error'><i style={{ fontSize: '.8em' }} className='fa fa-info-circle'></i> {t(errors[0])}</span>
        //         </div>}
        //         <div className="mb-3">
        //             <input value={formValues.id} onChange={handleChange} type="text"
        //                 className="form-control" name="id" placeholder={t('Telephone or e-mail address')} />
        //         </div>
        //         <div className="input-group mb-3">
        //             <input value={formValues.password} onChange={handleChange} type={!passwordVisibility ? "text" : "password"}
        //                 className="form-control" name="password" placeholder={t('password')} />
        //             <span className="input-group-text" id="togglePasswordVisibility"><i onClick={handleTogglePasswordVisibility} className='fa fa-eye'></i></span>
        //         </div>
        //         <div className='d-flex flex-column flex-lg-row align-items-start align-items-lg-center justify-content-around justify-content-lg-between gap-3 gap-lg-5 w-100'>
        //             <div className='d-inline-flex gap-2'>
        //                 <div className='gap-2 d-flex'><input style={{width:'15px'}} type="checkbox" id="remember" value="remember" /></div>
        //                 <label htmlFor='remember' className='ms-1'>{t('Remember me')}</label>
        //             </div>
        //             <NavLink className='ms-auto' to="/forgot-password">{t('Forgot your password ?')}</NavLink>
        //         </div>
        //         <button type='submit' className='btn btn-primary my-3 mt-4 w-100'>{t('Login')}</button>
        //     </form>
        // </div>

    );

}

export default LoginForm;