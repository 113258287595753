import React from 'react'
import AdminContainer from '../components/AdminContainer';
import '../../src/styles/AcademyPage.css';
import NoVote from '../components/vote/NoVote';
import DataTable from 'react-data-table-component';

const AcademyPage = () => {

  //testing data
    const columns = [
      {
        name: 'Title',
        selector: row => row.title,
      },
      {
        name: 'Year',
        selector: row => row.year,
      },
    ];

    const data = [
      {
        id: 1,
        title: 'Beetlejuice',
        year: '1988',
      },
      {
        id: 2,
        title: 'Ghostbusters',
        year: '1984',
      },
    ]

  return (
    <AdminContainer>
        <div className='d-flex  align-items-center justify-content-between'>
          <h4 className="fw-bold text-light text-uppercase">yafoot Academie</h4>
          <div className=''>
            <div className='d-flex gap-2'>
              <button
                className='fw-bold btnTop text-uppercase btn border'
              >
                <i className="fa fa-plus"></i>
                 ajouter
              </button>
              <button
              className='fw-bold btnTop text-uppercase btn border'
              >
                <i className="fa fa-eye"></i>
                gerer
              </button>
            </div>
          </div>
        </div>
        <div className="mt-3 d-flex justify-content-between w-100">
          <h5 className="fw-bold text-light text-uppercase">
            listes des Eleves
          </h5>
          <input
            maxLength={10}
            placeholder="Recherchez un un eleves"
            onChange={''}
            className="w-50 searchBar bg-transparent form-control mr-sm-2"
            type="search"
            aria-label="Search"
          ></input>
        </div>
        <div className="mt-3">
          <DataTable
            className="PaymentHistory"
            columns={columns}
            data={data}
            striped
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          />
        </div>
    </AdminContainer>
  )
}

export default AcademyPage ;