import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import MemberService from "../../services/MemberService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getMembers = createAsyncThunk(
    "member/all",
    async ({ reset, interval }) => {
        if (reset) {
            return initialState
        }
        const res = await MemberService.getAll(interval);
        return res.data;
    }
);


export const activeMember = createAsyncThunk(
    'member/active',
    async (data) => {
        const res = await MemberService.active(data);
        return res.data;
    }
)
export const adminMember = createAsyncThunk(
    'member/admin',
    async (data) => {
        const res = await MemberService.admin(data);
        return res.data;
    }
)

export const superAdminMember = createAsyncThunk(
    'member/super_admin',
    async (data) => {
        const res = await MemberService.superAdmin(data);
        return res.data;
    }
)



const memberSlice = createSlice({
    name: "member",
    initialState,
    extraReducers: {
        [getMembers.fulfilled]: (state, action) => {
            return action.payload
        },
        [activeMember.fulfilled]: (state, action) => {
            const index = state.findIndex(member => member.id === action.payload.id);
            state[index] = {
                ...state[index],
                ...action.payload,
            };
            return state
        },
        [adminMember.fulfilled]: (state, action) => {
            const index = state.findIndex(member => member.id === action.payload.id);
            state[index] = {
                ...state[index],
                ...action.payload,
            };
            return state
        },
        [superAdminMember.fulfilled]: (state, action) => {
            const index = state.findIndex(member => member.id === action.payload.id);
            state[index] = {
                ...state[index],
                ...action.payload,
            };
            return state
        },
    },
});

const { reducer } = memberSlice;
export default reducer;