import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { formatPrice } from "../../utils/utils";
import StripePaymentForm from "../form/StripePaymentForm";
import CircularLoader from "../loader/CircularLoader";
import "./../styles/CardPaymentForm.css";

const CardPaymentForm = ({ card, onCancel }) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

  return (
    <div className="CardPaymentForm p-3">
      <h6 className="d-flex align-items-center fw-bold text-dark gap-2">
        <i
          onClick={onCancel}
          className="backButton fa fa-arrow-left-long fa-1x"
          onMouseOver={() => {}}
        ></i>{" "}
        Paiement de la carte {card.type}
      </h6>
      <div className="d-flex gap-5 align-items-center p-0 m-0 mt-3 justify-content-center">
        <div className="p-0 m-0 w-25 d-none d-md-flex flex-column align-items-center justify-content-center">
          <img
            style={{ maxHeight: "50vh" }}
            className="img-fluid border shadow mx-auto border"
            src={card.image}
            alt="Card-Img"
          />

          <h5 className="text-uppercase text-center my-2">CARTE {card.type}</h5>
          <span className="d-flex justify-content-center">
            {formatPrice(card?.card_price)}
          </span>
        </div>
        <div className="d-flex flex-grow-1 justify-content-center p-0 m-0">
          {stripePromise ? (
            <Elements stripe={stripePromise}>
              <StripePaymentForm
                amount={card?.card_price}
                stripe_fee={card.stripe_fee}
                dataForPayment={{ ...card, payment_type: "card" }}
              />
            </Elements>
          ) : (
            <CircularLoader />
          )}
        </div>
      </div>
    </div>
  );
};

export default CardPaymentForm;
