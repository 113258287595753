import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { UserService } from "../../services/UserService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null

export const getCurrentUser = createAsyncThunk(
  "user/current",
  async () => {
    const res = await UserService.getCurrentUser();
    return res.data;
  }
);

export const updateUser = createAsyncThunk(
  "user/update",
  async (userData, { rejectWithValue }) => {
    const { data: user, id: userId } = userData
    try {

      const res = await UserService.UpdateUser({ data: user, id: userId });
      // console.log(res.data);
      return res.data;
    } catch (err) {
      return rejectWithValue(err.response.data)
    }
  }
);

export const logoutCurrentUser = createAsyncThunk(
  "user/logout",
  async () => {
    return null;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: {
    [getCurrentUser.fulfilled]: (state, action) => {
      return action.payload
    },
    [updateUser.fulfilled]: (state, action) => {
      return action.payload
    },
    [updateUser.rejected]: (state, action) => {
      return action.payload
    },
    [logoutCurrentUser.fulfilled]: (state, action) => {
      return null
    },
  },
});

const { reducer } = userSlice;
export default reducer;