import { useState } from "react"
import CircularLoader from "./loader/CircularLoader";
import { Modal } from "./Modal";


const DownloadViewer = ({ open, onClose, onDownloadPDF, onDownloadImage }) => {
    const [onDownload, setOnDownload] = useState(false);

    const __onDownloadPDF = async (e) => {
        setOnDownload(true)
        await onDownloadPDF(e)
        setOnDownload(false)
    }

    const __onDownloadImage = async (e) => {
        setOnDownload(true)
        await onDownloadImage(e)
        setOnDownload(false)
    }
    

    return <Modal onClose={onClose} open={open} backdrop={open} title='Téléchargement'>
        {!onDownload && <div className="d-flex flex-column justify-content-between p-3">
            <div>
                Sous quel format voulez vous téléchargez votre YAFOOT carte ?
            </div>
            <div className="d-flex justify-content-end w-100 mt-3">
                <button className='downloadBtn mx-2' onClick={__onDownloadPDF}> <i className="fa fa-file-pdf"></i> PDF</button>
                <button className='downloadBtn mx-2' onClick={__onDownloadImage}> <i className="fa fa-image"></i> Image</button>
            </div>
        </div>}
        {onDownload && <CircularLoader content={"Téléchargement en cours..."} />}
    </Modal>
}

export default DownloadViewer;