/* eslint-disable no-unused-vars */
import { useMemo, useRef, useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../components/AdminContainer";
import AddGameForm from "../components/form/game/AddGameForm";
import EditGameForm from "../components/form/game/EditGameForm";
import CircularLoader from "../components/loader/CircularLoader";
import useLanguage from "../hook/useLanguage";
import { getGames } from "../redux/slices/gameSlice";
import {
  adaptTeamName,
  filterGame,
  filterGame as _filterGame,
  parseReadableDate,
} from "../utils/utils";
import "./../styles/ShopPage.css";
import "./../styles/GamePage.css";
import NoVote from "../components/vote/NoVote";
import GameHistory from "../components/game/GameHistory";
import GameHistoryHeader from "../components/form/game/GameHistoryHeader";

const GamePage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const gameData = useSelector((state) => state.game);
  const userData = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const shopCartRef = useRef();
  const [step, setStep] = useState(0);
  const [onAdd, setOnAdd] = useState(false);
  const [toEdit, setToEdit] = useState(false);

  useEffect(() => {
    if (!gameData || gameData.length < 1) dispatch(getGames());
  }, [dispatch, gameData]);

  const gameDataColumns = useMemo(
    () => [
      {
        name: "Equipe 1",
        selector: (row) => {
          return (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                style={{ width: "40px", height: "40px", borderRadius: "25px" }}
                src={row.team1.logo}
                alt={row.team1.name}
              />
              <span className="d-flex flex-column justify-content-center align-items-center">
                {adaptTeamName(row.team1.name)}
              </span>
            </div>
          );
        },
      },
      {
        name: "Equipe 2",
        selector: (row) => {
          return (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                style={{ width: "40px", height: "40px", borderRadius: "25px" }}
                src={row.team2.logo}
                alt={row.team2.name}
              />
              <span>{adaptTeamName(row.team2.name)}</span>
            </div>
          );
        },
      },
      {
        name: "Stade",
        selector: (row) => {
          return row ? row.stadium : "";
        },
      },
      {
        name: "Date",
        selector: (row) => {
          return parseReadableDate(new Date(row.start_at));
        },
        sortable: true,
        sortFunction: (a, b) => new Date(a.start_at) - new Date(b.start_at),
      },
      {
        name: "Score",
        selector: (row) => {
          return (
            <div className="voteResult border w-100 fs-6 px-3">{row.score}</div>
          );
        },
        sortable: false,
      },
      {
        name: "Actions",
        selector: (row) => {
          return (
            <div className="d-flex justify-content-between">
              <button
                onClick={() => {
                  setToEdit(row);
                }}
                title={"Modifier les infos du match"}
                style={{
                  color: "#f5f5f5",
                  backgroundColor: "rgb(0, 238, 161)",
                  fontWeight: "600",
                }}
                className="actionBtn ms-2 p-2"
              >
                <i className="fa fa-edit" aria-hidden="true"></i>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  const filterGame = useMemo(() => {
    return (played) => _filterGame(gameData, played);
  }, [gameData]);

  return (
    <AdminContainer>
      {!onAdd && !toEdit ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="fw-bold text-light text-uppercase">matchs</h4>
            <div className="">
              {userData && userData.admin === "1" && (
                <div className="d-flex gap-2">
                  <button
                    onClick={() => setOnAdd(true)}
                    className="btn border openCart fw-bold text-uppercase"
                  >
                    {" "}
                    <i className="fa fa-plus"></i> ajouter
                  </button>
                  <button
                    onClick={() => setStep(step === 0 ? 1 : 0)}
                    className="btn border openCart fw-bold text-uppercase"
                  >
                    {" "}
                    <i
                      className={step === 0 ? "fa fa-eye" : "fa fa-eye-slash"}
                    ></i>{" "}
                    gérer
                  </button>
                </div>
              )}
            </div>
          </div>
          {step === 0 ? (
            gameData !== null ? (
              <div className="">
                {gameData.length > 0 ? (
                  <>
                    <GameHistoryHeader
                      lastGame={filterGame(gameData, true).at(-1)}
                      nextGame={filterGame(false).at(0)}
                    />
                    <GameHistory data={gameData} />
                  </>
                ) : (
                  <NoVote label="Aucun match programmé" />
                )}
              </div>
            ) : (
              <CircularLoader />
            )
          ) : (
            <div className="mt-3">
              <DataTable
                className="PaymentHistory"
                columns={gameDataColumns}
                data={gameData}
                striped
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              />
            </div>
          )}
        </>
      ) : onAdd && !toEdit ? (
        <AddGameForm
          className={"col-12 col-lg-8"}
          onBack={() => setOnAdd(false)}
        />
      ) : (
        <EditGameForm
          game={toEdit}
          className={"col-12 col-lg-8"}
          onBack={() => setToEdit(false)}
        />
      )}
    </AdminContainer>
  );
};

export default GamePage;
