/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next';
import {
  NavLink,
  useNavigate,
} from "react-router-dom";
import AppContainer from "../components/AppContainer";
import OtpForm from "../components/form/OtpForm";
import RegisterForm from '../components/form/RegisterForm';
import useLanguage from '../hook/useLanguage';

import "./../styles/LoginPage.css";

const OtpPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage()
  const navigate = useNavigate();

  const handleResetOTPCode = () => {
    localStorage.clear();
    navigate("/register");
  };

  return (
    <AppContainer uniqueChild={true}>
      <OtpForm />

      <div className="d-flex mt-4 justify-content-between align-items-center gap-3">
        <div>
          <div className="text-light fw-bold">
            Vous n'avez pas recu le code ?
          </div>
          <button className="btn btn-primary">renvoyer le code otp</button>
        </div>
        <div>
          <button
            onClick={handleResetOTPCode}
            className="btn btn-danger text-uppercase"
          >
            annuler
          </button>
        </div>
      </div>
    </AppContainer>
  );
};

export default OtpPage;
