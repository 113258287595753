import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {PlayerService} from "../../services/PlayerService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getPlayers = createAsyncThunk(
    "player/all",
    async () => {
        const res = await PlayerService.all();
        return res.data;
    }
);

export const addPlayer = createAsyncThunk(
    "player/add",
    async (player) => {
        const res = await PlayerService.add(player);
        return res.data;
    }
);

export const updatePlayer = createAsyncThunk(
    "player/update",
    async (player) => {
        const res = await PlayerService.update(player);
        return res.data;
    }
);

export const deletePlayer = createAsyncThunk(
    "player/delete",
    async (id) => 
    {
        const res = await PlayerService.deletes(id);
        return res;
    }
);

const playerSlice = createSlice({
    name: "player",
    initialState,
    extraReducers: {
        [getPlayers.fulfilled]: (state, action) => {
            return action.payload
        },
        [addPlayer.fulfilled]: (state, action) => {
            if (state) {
                state = [...state, action.payload.data]
            } else {
                state = [action.payload.data]
            }
            return state
        },
        [updatePlayer.fulfilled]: (state, action) => {
            const index = state.findIndex(player => player.id === action.payload.id);
            state[index] = {
                ...state[index],
                ...action.payload,
            };
            return state
        },
        [deletePlayer.fulfilled]: (state, action) => {
            const index = state.findIndex(player => player.id === action.payload.id);
            state[index] = {
                ...state[index],
                ...action.payload,
            };
            return state
        },

    },
});


const { reducer } = playerSlice;
export default reducer;