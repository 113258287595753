import React from 'react'
import AppContainer from '../components/AppContainer'
import DonationForm from '../components/form/DonationForm'

const DonPage = () => {
  return (
    <AppContainer>
        <DonationForm />
    </AppContainer>
  )
}

export default DonPage
