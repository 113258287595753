/* eslint-disable no-unused-vars */
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import usePasswordVisibility from "../../hook/usePasswordVisibility";
import "./../styles/LoginForm.css";
import "react-phone-number-input/style.css";
import { useEffect, useRef, useState } from "react";
import useLanguage from "../../hook/useLanguage";
// import useForm from "../../hook/useForm";
// import { isValidPhoneNumber } from "react-phone-number-input";
// import { isEmail } from "../../utils/utils";
import StripeDonationForm from "./StripeDonationForm";
import CircularLoader from "../loader/CircularLoader";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "../styles/Checkbox.css"
import { createDonation } from "../../redux/slices/donationSlice";
import { isEmail } from "../../utils/utils";
import useAuthToken from "../../hook/useAuthToken";

const DonationForm = () => {
  const userData = useSelector((state) => state.user);
  const [language, t, handleChangeLanguage] = useLanguage();
  const [isChecked, setIsChecked] = useState(false);
  const [activeButton, setActiveButton] = useState(false)

  const [formValues, setFormValues] = useState({ ...userData, amount: "" });
  const [errors, setErrors] = useState({});

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch()

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleChangeCheck = (event) => {
    const { name, value } = event.target;
    setIsChecked((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const formRef = useRef()

  const handlePhoneChange = (value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      phone: value,
    }));
  };
  const submitButtonRef = useRef()
  const validForm = () => {
    const errorsValidations = {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      amount: ''
    }
    let valid = true
    setErrors(errorsValidations)
    if (!formValues.first_name && !isChecked) {
      errorsValidations.first_name = 'invalid name'
      valid = false
    }
    if (!formValues.last_name && !isChecked) {
      errorsValidations.last_name = 'invalid last name'

      valid = false
    }
    if (!isEmail(formValues.email) && formValues.email) {
      errorsValidations.email = 'invalid email'
      valid = false
    }
    if (!formValues.phone) {
      errorsValidations.email = 'empty phone number'
      valid = false
    }
    if (!formValues.amount) {
      errorsValidations.amount = 'please enter an amount'
      valid = false
    }
    setErrors(errorsValidations)
    return valid
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (validForm()) {
      submitButtonRef.current.setAttribute("disabled", "true");
      submitButtonRef.current.innerHTML =
        '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';
      const data = new FormData(formRef.current)
      console.log(data)
      setStep(1)
      dispatch(createDonation(data)).then((data) => {
        if (data.errors) {
          toast.error(data.errors.join("<br/>"));
        } else {
          toast.success("Thank to enter your payment information");
        }
      })
    }

  }
  const authToken = useAuthToken()

  useEffect(() => {
    if (((formValues.first_name && formValues.last_name && formValues.email && formValues.phone && formValues.amount) && !isChecked) || ((formValues.phone && formValues.amount) && isChecked)) {
      setActiveButton(false)
    } else {
      setActiveButton(true)
    }
  }, [formValues, isChecked])


  return (
    <div className="LoginForm shadow">
      {step === 0 ? (
        <form className="p-2 m-2 py-4" ref={formRef}>
          <h3 className="mb-4 d-flex align-items-center gap-2">
            {t("Donation")} <i className="fas mb-2 fa-hand-holding-usd"></i>
          </h3>
          <div className={`row ${!isChecked ?? 'collapse show'}`} >
            {!isChecked &&
              (<>
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <input
                      value={formValues.last_name}
                      onChange={handleChange}
                      type="text"
                      name="last_name"
                      className="form-control"
                      placeholder={t("Last name")}
                    />
                    {errors.last_name && (
                      <span className="form-error">
                        <i
                          style={{ fontSize: ".8em" }}
                          className="fa fa-info-circle"
                        ></i>{" "}
                        {t(errors.last_name)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <input
                      value={formValues.first_name}
                      onChange={handleChange}
                      type="text"
                      className="form-control"
                      name="first_name"
                      placeholder={t("First name")}
                    />
                    {errors.first_name && (
                      <span className="form-error">
                        <i
                          style={{ fontSize: ".8em" }}
                          className="fa fa-info-circle"
                        ></i>{" "}
                        {t(errors.first_name)}
                      </span>
                    )}
                  </div>
                </div>
              </>)
            }
            {!authToken && (
              <div className="col-12 col-lg-6">
                <div className="d-flex gap-2 align-items-center">
                  <div className="checkbox-wrapper-4 d-flex">
                    <input className="inp-cbx" id="anonymous" type="checkbox" checked={isChecked} onChange={handleChangeCheck} onClick={() => setIsChecked(!isChecked)} />
                    <label className="cbx" htmlFor="anonymous"
                    ><span>
                        <svg width="12px" height="10px">
                          <use xlinkHref="#check-4"></use></svg></span
                      ></label>
                    <svg className="inline-svg">
                      <symbol id="check-4" viewbox="0 0 12 10">
                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                      </symbol>
                    </svg>
                  </div>
                  <span className={`checkbox-wrapper-label`}>{t('Rester anonyme')}</span>
                </div>
              </div>)}
          </div>
          <div className="mb-3">
            <input
              value={formValues.email}
              type="email"
              onChange={handleChange}
              className="form-control"
              name="email"
              placeholder={t("Email")}
            />
            {errors.email && (
              <span className="form-error">
                <i
                  style={{ fontSize: ".8em" }}
                  className="fa fa-info-circle"
                ></i>{" "}
                {t(errors.email)}
              </span>
            )}
          </div>
          <div className="mb-3 mt-5">
            <PhoneInput
              name="phone"
              defaultCountry="CM"
              placeholder={t("Phone number")}
              value={formValues.phone}
              onChange={(value) => {
                handlePhoneChange(value);
              }}
            />
            {errors.phone && (
              <span className="form-error">
                <i
                  style={{ fontSize: ".8em" }}
                  className="fa fa-info-circle"
                ></i>{" "}
                {t(errors.phone)}
              </span>
            )}
          </div>

          <div className="mb-3">
            <input
              required
              value={formValues.amount}
              min={5000}
              type="number"
              step={500}
              onChange={handleChange}
              className="form-control"
              name="amount"
              placeholder={t("Amount")}
            />
            {errors.amount && (
              <span className="form-error">
                <i
                  style={{ fontSize: ".8em" }}
                  className="fa fa-info-circle"
                ></i>{" "}
                {t(errors.amount)}
              </span>
            )}
          </div>

          <button
            onClick={handleSubmit}
            type="submit"
            ref={submitButtonRef}
            disabled={activeButton}
            className="btn btn-primary my-3 mt-5 w-100"
          >
            {t("make my donation")} <i className="fas ms-2 fa-handshake"></i>
          </button>
        </form>
      ) : stripePromise ? (
        <Elements stripe={stripePromise}>
          <StripeDonationForm
            onGoBack={() => {
              setStep(0);
            }}
            amount={formValues.amount}
            title={
              <div>
                {t("Donation")} <i className="fas mb-3 fa-hand-holding-usd"></i>
              </div>
            }
            stripe_fee={0}
            dataForPayment={formValues}
            onPaymentSuccess={() => console.log("payment success !")}
          />
        </Elements>
      ) : (
        <CircularLoader />
      )}
    </div>
  );
};

export default DonationForm;