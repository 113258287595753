import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import ComplaintService from "../../services/ComplaintService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;


export const createComplaint = createAsyncThunk(
  "complaints/create",
  async (complaint) => {
    const res = await ComplaintService.addComplaint(complaint);
    if(! res.data.error){
      return res.data;
    }
    
  }
);

export const getComplaints = createAsyncThunk(
  "complaint/all",
  async () => {
      const res = await ComplaintService.getAll();
      return res.data;
  }
);

const cartSlice = createSlice({
  name: "complaint",
  initialState,
  extraReducers: {
    [createComplaint.fulfilled]: (state, action) => {
      if(state===null){
        return [action.payload]
      }
      
      state.push(action.payload);

      return state
  },
    [getComplaints.fulfilled]: (state, action) => {
      return action.payload;
  },
  },
});

const { reducer } = cartSlice;
export default reducer;