/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AdminContainer from "../components/AdminContainer";
import Box from "../components/Box";
import { CardCarousel } from "../components/card/CardCarousel";
import CardList from "../components/card/CardList";
import CardPayementForm from "../components/card/CardPaymentForm";
import CircularLoader from "../components/loader/CircularLoader";
import useLanguage from "../hook/useLanguage";
import { getCards } from "../redux/slices/cardSlice";
import "./../styles/CardPage.css";

const CardPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const cardData = useSelector((state) => state.card);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [cardItems, setCardItems] = useState([]);
  const [
    activeCard,
    setActiveCard,
  ] = useState(null);
  const [cardToBuy, setCardToBuy] = useState(null);
  const [
    activeCardIndex,
    setActiveCardIndex
  ] = useState(2);

  useEffect(() => {
    if (cardData === null) {
      dispatch(getCards());
    }
  }, [cardData, dispatch]);

  useEffect(() => {
    setCardItems(cardData);
  }, [cardData]);

  useEffect(() => {
    cardItems && setActiveCard(cardItems[2]);
  }, [cardItems, setActiveCard]);

  const handlePrev = () => {
    setCardItems((prevCards) => {
      const lastCard = prevCards[prevCards.length - 1];
      const newCards = [lastCard, ...prevCards.slice(0, prevCards.length - 1)];
      return newCards;
    });
  };

  const handleNext = () => {
    setCardItems((prevCards) => {
      const firstCard = prevCards[0];
      const newCards = [...prevCards.slice(1), firstCard];
      return newCards;
    });
  };

  const handleSelect = (selectedCard) => {
    if (userData.image === null) {
      return toast.warning(
        <div>
          Vous n'avez pas de photo de profil, veuillez glisser une photo dans
          la{" "}
          <span onClick={() => navigate("/profil")} className="deeplink">
            section profil (ou cliquez dessus.) <i className="fa fa-arrow-right" aria-hidden="true"></i>
          </span>{" "}
          avant d'acheter votre YAFOOT carte.
        </div>,
        { autoClose: false }
      );
    }
    setCardToBuy(selectedCard);
  };

  const handlePayCard = (card) => {};

  const handleCancelPayment = () => {
    setCardToBuy(null);
  };

  const [manage, setOnManage] = useState(false);

  return (
    <AdminContainer>
      {!cardToBuy && cardItems && (
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="fw-bold text-light text-uppercase">
              carte YAFOOT club
            </h4>
            <div className="">
              {userData && userData.admin === 1 && (
                <button
                  onClick={() => setOnManage(!manage)}
                  className="btn border openCart fw-bold text-uppercase"
                >
                  {" "}
                  <i
                    className={!manage ? "fa fa-eye" : "fa fa-eye-slash"}
                  ></i>{" "}
                  gérer
                </button>
              )}
            </div>
          </div>
          {!manage ? (
            cardItems && (
              <CardCarousel
                onSelect={handleSelect}
                onPrev={handlePrev}
                onNext={handleNext}
                cardItems={cardItems}
                activeCardIndex={activeCardIndex}
              />
            )
          ) : (
            <CardList />
          )}
        </div>
      )}
      {!cardToBuy && !cardItems && <Box content={<CircularLoader />} />}
      {cardToBuy && (
        <CardPayementForm card={cardToBuy} onCancel={handleCancelPayment} />
      )}
    </AdminContainer>
  );
};

export default CardPage;
