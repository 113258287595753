// import { configureStore } from "@reduxjs/toolkit";
// import { setupListeners } from "@reduxjs/toolkit/dist/query";
// import userReducer from "./slices/userSlice"
// import cardReducer from "./slices/cardSlice"
// import paymentReducer from "./slices/paymentSlice"
// import subscriptionReducer from "./slices/subscriptionSlice"
// import seasonReducer from "./slices/seasonSlice"
// import printRequestReducer from "./slices/printRequestSlice.js"
// import itemReducer from "./slices/itemSlice.js"
// import cartReducer from "./slices/cartSlice.js"
// import commandReducer from "./slices/commandSlice.js"
// import playerReducer from "./slices/playerSlice.js"
// import complaintReducer from "./slices/complaintSlice"
// import memberReducer from "./slices/memberSlice"
// import candidateReducer from "./slices/candidateSlice"
// import candidatePaymentReducer from "./slices/candidatePaymentSlice"
// import gameReducer from "./slices/gameSlice"
// import voteReducer from "./slices/voteSlice.js"
// import donationReducer from "./slices/donationSlice"


// export const store = configureStore({
//     reducer: {
//         user: userReducer,
//         card: cardReducer,
//         season: seasonReducer,
//         item: itemReducer,
//         cart: cartReducer,
//         command: commandReducer,
//         player: playerReducer,
//         complaint: complaintReducer,
//         member: memberReducer,
//         candidate: candidateReducer,
//         applications: candidatePaymentReducer,
//         game: gameReducer,
//         vote: voteReducer,
//         donation: donationReducer,
        
//         printRequest: printRequestReducer,
//         subscription: subscriptionReducer,
//         payment: paymentReducer,
//     },
//     devTools: process.env.NODE_ENV === 'development',
// })

// setupListeners(store.dispatch)  


import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import userReducer from "./slices/userSlice"
import cardReducer from "./slices/cardSlice"
import paymentReducer from "./slices/paymentSlice"
import subscriptionReducer from "./slices/subscriptionSlice"
import seasonReducer from "./slices/seasonSlice"
import printRequestReducer from "./slices/printRequestSlice.js"
import itemReducer from "./slices/itemSlice.js"
import cartReducer from "./slices/cartSlice.js"
import commandReducer from "./slices/commandSlice.js"
import playerReducer from "./slices/playerSlice.js"
import complaintReducer from "./slices/complaintSlice"
import memberReducer from "./slices/memberSlice"
import candidateReducer from "./slices/candidateSlice"
import candidatePaymentReducer from "./slices/candidatePaymentSlice"
import gameReducer from "./slices/gameSlice"
import voteReducer from "./slices/voteSlice.js"
import donationReducer from "./slices/donationSlice"

import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";

const persistConfig = {
  key: "root",
  storage,
};

const  persistedUserReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
    reducer: {
        user: persistedUserReducer,
        card: cardReducer,
        season: seasonReducer,
        item: itemReducer,
        cart: cartReducer,
        command: commandReducer,
        player: playerReducer,
        complaint: complaintReducer,
        member: memberReducer,
        candidate: candidateReducer,
        applications: candidatePaymentReducer,
        game: gameReducer,
        vote: voteReducer,
        donation: donationReducer,
        
        printRequest: printRequestReducer,
        subscription: subscriptionReducer,
        payment: paymentReducer,
    },
    devTools: process.env.NODE_ENV === 'development',
})

setupListeners(store.dispatch)  