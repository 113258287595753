import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = () => {
    return axios.get(`admin/donation`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}
const createDonation = (payment) => {
    return axios.post('public/donation/create', { ...payment })
        .then((data) => {
            return data;
        })
}


const DonationService = {
    getAll,
    createDonation
}

export default DonationService;