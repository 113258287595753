import { useState } from "react";
import ReactCrop from "react-image-crop";
import "./../styles/CropImageModal.css";
import { Modal } from "./Modal";

const CropImageModal = ({ open, image, onLoad, onChange, onSave, onClose }) => {
  const [crop, setCrop] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 250,
    height: 250,
    aspect: 1,
  });

  const [cropData, setCropData] = useState(null);

  // const [open, setOpen] = useState(open)

  const handleSave = (e) => {
    try {
      const canvas = document.createElement("canvas");
      const scaleX = cropData.naturalWidth / cropData.width;
      const scaleY = cropData.naturalHeight / cropData.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        cropData,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      const base64Image = canvas.toDataURL("image/jpeg", 1);

      canvas.toBlob(function (blob) {
        if (base64Image !== "data:,") {
          onSave({ blob: blob, base64: base64Image });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleClose = () => {
    setCropData(null);
    onClose();
  };

  const handleLoad = (data) => {
    setCropData(data);
  };

  return (
    <Modal onClose={onClose} open={open}>
      <div className="">
        <ReactCrop
          src={image}
          onImageLoaded={handleLoad}
          crop={crop}
          onChange={setCrop}
          circularCrop={true}
        />
      </div>

      <div className="d-flex justify-content-end align-item-end gap-3 m-2">
        {/* <button type="button" className="btn btn-secondary w-25" onClick={handleClose} >Annuler</button> */}
        <button
          type="button"
          className=" btn btn-primary w-25"
          onClick={handleSave}
        >
          Valider
        </button>
      </div>
    </Modal>
  );
};

export default CropImageModal;
