import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // Navbar
      'About': 'About',
      'Contact': 'Contact',
      // Login and Registration form
      'FROM DOUALA': 'OF DOUALA',
      'Registration': 'Registration',
      'Last name': 'Last name',
      'First name': 'First name',
      'Email': 'Email',
      'Phone number': 'Phone number',
      'City': 'City',
      'Address': 'Address',
      'Password': 'Password',
      'Register': 'Register',
      'Donation': 'Make a donation',
      'Amount': 'Amount',
      'make my donation': 'make my donation',
      'Already have an account?': 'Already have an account?',
      'Login': 'Login',
      'Join the team': 'Join the team',
      'Telephone or e-mail address': 'Phone number or e-mail address',
      'Forgot your password ?': 'Forgot your password ?',
      'Remember me': 'Remember me',
      'Reset your password': 'Reset your password',
      'reset': 'Reset',

      'mobile money payment': 'Mobile money payment',
      'payment by credit card': 'Payment by credit card',
      'Select mobile operator': 'Select mobile operator',
      'Payment by': 'Payment by',

      
      'incorrect last name': 'incorrect last name',
      'incorrect first name': 'incorrect first name',
      'incorrect email': 'incorrect email',
      'incorrect phone': 'incorrect phone number',
      'incorrect city': 'incorrect city',
      'incorrect address': 'incorrect address',
      'incorrect password': 'password lenght must be greather than six',

      'Login successfully redirection...': 'Login successfully redirection...',

      'Your password has been successfully changed ! we will redirect you to the login page...': 'Your password has been successfully changed ! we will redirect you to the login page...',
      'The reset link has been sent to your email/phone please verify it.': 'The reset link has been sent to your email/phone please verify it.',

    }
  },
  fr: {
    translation: {
      'About': 'À propos',
      'Contact': 'Contact',
      // Login and Registration form
      'FROM DOUALA': 'DE DOUALA',
      'Registration': 'Inscription',
      'Last name': 'Nom',
      'First name': 'Prénom',
      'Email': 'Email',
      'Phone number': 'Numéro de téléphone',
      'City': 'Ville',
      'Address': 'Adresse',
      'Password': 'Mot de passe',
      'Register': 'S\'inscrire',
      'make my donation': 'faire mon don',
      'Donation': 'Faire un don',
      'Amount': 'Montant',
      'Already have an account?': 'Déjà un compte?',
      'Login': 'Se connecter',
      'Join the team': 'Rejoindre l\'équipe',
      'Telephone or e-mail address': 'Téléphone ou adresse e-mail',
      'Forgot your password ?': 'Mot de passe oublié ?',
      'Remember me': 'Se souvenir de moi',
      'Reset your password': 'Réinitialiser votre mot de passe',
      'reset': 'Réinitialiser',

      'mobile money payment': 'Paiement par mobile money',
      'payment by credit card': 'Paiement par carte de crédit',
      'Select mobile operator': 'Sélectionner l\'opérateur mobile',
      'Payment by': 'Paiment par',

      // validation rules
      'incorrect last name': 'nom incorrect',
      'incorrect first name': 'prénom incorrect',
      'incorrect email': 'adresse mail incorrect',
      'incorrect phone': 'numéro de téléphone incorrect',
      'incorrect city': 'ville incorrect',
      'incorrect address': 'adresse incorrect',
      'incorrect password': 'mot de passe trop court',

      'Login successfully redirection...': 'Connexion réussie redirection...',

      'Your password has been successfully changed ! we will redirect you to the login page...': 'Votre mot de passe a été changé avec succès ! nous vous redirigerons vers la page de connexion...',
      'The reset link has been sent to your email/phone please verify it.': 'Le lien de réinitialisation a été envoyé à votre (adresse e-mail)/(numéro de téléphone), veuillez le vérifier.',
    }
  }
};

const defaultLang = localStorage.getItem('defaultLang') ?? 'en'

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLang,

  interpolation: {
    escapeValue: false
  }
});

export default i18n;