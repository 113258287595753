import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const all = () => {
    return axios.get(`/admin/games`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        // console.log(data.data);
        return data;
    })
}


const add = (game) => {
    //console.log(userToPatch);
    return axios
        .post(`/admin/games`, game, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "multipart/form-data"
            }
        })
        .then((data) => {
            //console.log(data);
            return data;
        })
        .catch((error) => console.log('Error: ', error));

}

const update = (game) => {
    //console.log(userToPatch);
    return axios
        .post(`/admin/games/update`, game, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "multipart/form-data"
            }
        })
        .then((data) => {
            //console.log(data);
            return data;
        })
        .catch((error) => console.log('Error: ', error));
}


export const GameService = {
    all, add, update
}

