import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect } from 'react';
import StripeItemForm from '../form/StripeItemForm';
import CircularLoader from '../loader/CircularLoader';
import './../styles/CardPaymentForm.css'

const ItemPaymentForm = ({ carts, onCancel }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    const i = (value) => parseInt(value)

    const getPrice = (data) => {
        return data.reduce((prev, next) => {
            return prev + i(next.price) * i(next.quantity)
        }, 0)
    }

    useEffect(() => {
        carts.length===0 && onCancel()
    }, [carts, onCancel])

    return <div className="CardPaymentForm col-12 col-md-9 col-lg-8 p-3">
        <h6 className='d-flex fw-bold text-dark gap-2'><i onClick={onCancel} className='backButton fa fa-arrow-left-long fa-1x'></i> Paiement des articles</h6>
        <div className='p-0 m-0'>
            {stripePromise ? <Elements stripe={stripePromise}>
                <StripeItemForm
                    amount={getPrice(carts)}
                    stripe_fee={8}
                    dataForPayment={{ ...carts, payment_type: "command" }}
                />
            </Elements> : <CircularLoader />}
        </div>
    </div>
}


export default ItemPaymentForm;