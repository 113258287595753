import { useState } from 'react';

const usePasswordVisibility = (visible=false) => {
    const [passwordVisibility, setPasswordVisibility] = useState(visible)

    const handleTogglePasswordVisibility = (e) => { 
        const target = e.target;
        setPasswordVisibility(!target.classList.contains('fa-eye'));
        if (target.classList.contains('fa-eye')) {
            target.classList.remove('fa-eye');
            target.classList.add('fa-eye-slash');
        } else {
            target.classList.remove('fa-eye-slash');
            target.classList.add('fa-eye');
        }
    }

    return [passwordVisibility, handleTogglePasswordVisibility];

}

export default usePasswordVisibility;