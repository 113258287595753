import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;


const all = () => {
    return axios.get(`/players`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        // console.log(data.data);
        return data;
    })
}


const add = (player) => {
    //console.log(userToPatch);
    return axios
        .post(`/admin/players`, player, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "multipart/form-data"
            }
        })
        .then((data) => {
            //console.log(data);
            return data;
        })
        .catch((error) => console.log('Error: ', error));

}

const update = (player) => {
    //console.log(userToPatch);
    return axios
        .post(`/admin/players/update`, player, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
                "Content-Type": "multipart/form-data"
            }
        })
        .then((data) => {
            //console.log(data);
            return data;
        })
        .catch((error) => console.log('Error: ', error));
}

const deletes = (id) => {
   
    return axios
        .get(`/admin/players/delete/${id}` , {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
            }
        })
        .then((data) => {
            console.log(data);
            return data;
        })
        .catch((error) => console.log('Error: ', error));
}

export const PlayerService = {
    all, add, update , deletes
}

