import axios from "axios";
// import { parsePhoneNumber } from "react-phone-number-input";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "authToken"
)}`;

const getCurrentUser = (token) => {
  return axios
    .get(`user`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    })
    .then((data) => {
      // console.log(data);
      return data;
    });
};

export const UpdateUser = (userToPatch)  => {

  return axios
  .post(`users`, userToPatch.data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  })
  .then((data) => {
    // console.log(data);
    return data;
  });
  
//   fetch(`http://127.0.0.1:8000/api/users/${userToPatch.id}`,
//           {
//       method: 'PUT',
//       body: userToPatch.data,
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("authToken")}`,
//       }
//     }
//   ).then((data) => {
//       console.log(data);
//   })
// ;
  }

  

export const UpdateUserById = (userToPatch) => {
  //console.log(userToPatch);
  return axios
    .post(`users`, userToPatch, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((data) => {
      // console.log(data);
      return data;
    });
};

// const UpdateUser = (userToPatch) => {
//     const formData = new FormData();

//     // Append all user data to formData
//     Object.keys(userToPatch).forEach(key => {
//         if (key === 'image' && userToPatch[key] instanceof File) {
//             formData.append(key, userToPatch[key], userToPatch[key].name);
//         } else if (userToPatch[key] !== null && userToPatch[key] !== undefined) {
//             formData.append(key, userToPatch[key]);
//         }
//     });

//     return axios
//         .put(`users/me`, formData, {  // Changed to 'users/me'
//             headers: {
//                 "Authorization": `Bearer ${localStorage.getItem("authToken")}`,
//                 'Content-Type': 'multipart/form-data'
//             }
//         })
//         .then((response) => {
//             return response.data;
//         })
//         .catch((error) => {
//             console.error('Error updating user:', error.response?.data || error.message);
//             throw error;
//         });
// }

const signup = async (data) => {
  console.log(data);

  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response) => {
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}signup`,
        method: "post",
        data: {
          ...data,
        },
        headers: {
          "X-CSRF-TOKEN": response.data,
          "Content-Type": "multipart/form-data",
        },
      });
    });
};

const signin = async (data) => {
  
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response) => {
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}signin`,
        method: "post",
        params: {
          ...data,
        },
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

const verifyOTP = (otp) => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}verify-otp/${otp}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });
};

const resendOTP = () => {
  return axios({
    url: `${process.env.REACT_APP_API_BASE_URL}resend-otp`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    },
  });
};
const askNewPasswordLink = (data) => {
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response) => {
      // console.log(response.data);
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}reset-password`,
        method: "get",
        params: {
          ...data,
        },
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

const changePassword = ({ token, password }) => {
  return axios
    .get(`${process.env.REACT_APP_API_BASE_URL}token`)
    .then((response) => {
      return axios({
        url: `${process.env.REACT_APP_API_BASE_URL}reset-password/${token}/${password}`,
        method: "get",
        headers: {
          "X-CSRF-TOKEN": response.data,
        },
      });
    });
};

export const UserService = {
  getCurrentUser,
  UpdateUser,
  signup,
  signin,
  verifyOTP,
  resendOTP,
  askNewPasswordLink,
  changePassword,
};
