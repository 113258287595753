import AppContainer from '../components/AppContainer';
import ForgotPasswordForm from '../components/form/ForgotPasswordForm';
import './../styles/LoginPage.css'

const ForgotPasswordPage = () => {

    return <AppContainer>
        <ForgotPasswordForm />
    </AppContainer>
}

export default ForgotPasswordPage;