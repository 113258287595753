/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
import useLanguage from "../../hook/useLanguage";
import { UserService } from "../../services/UserService";
// import { sleep } from "../../utils/utils";
import "./../styles/OtpForm.css";

const OtpForm = () => {
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState(null);
  const [language, t, handleChangeLanguage] = useLanguage();
  const navigate = useNavigate();

  const handleChange = (enteredOtp) => {
    setOtp(enteredOtp);
  };

  const __handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.setAttribute("disabled", "true");
    e.target.innerHTML =
      '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';

    if (otp.length === 4) {
      UserService.verifyOTP(otp)
        .then((json) => {
          localStorage.removeItem("otpConfirmation");
          localStorage.setItem("authToken", json.data.token);
          setTimeout(() => {
            navigate("/card");
          }, 500);
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.errors) {
            setErrors(data.errors);
          }
        })
        .finally(() => {
          e.target.removeAttribute("disabled");
          e.target.innerHTML = t("Validate");
        });
    }
  };

  return (
    <div className="OtpForm LoginForm p-2 p-lg-4">
      <h3 className="text-uppercase mb-3">Validation du compte</h3>
      <div className="alert alert-warning mb-4" role="alert">
        Un code OTP a été envoyé à votre adresse e-mail / numéro de téléphone.
        <br />
        Veuillez le saisir pour continuer.
      </div>
      {errors && (
        <span className="form-error">
          <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
          {t(errors[0])}
        </span>
      )}
      <OtpInput
        containerStyle={"d-flex justify-content-between"}
        placeholder={0}
        inputStyle={"mx-1 w-100 form-control form-control-otp"}
        value={otp}
        onChange={handleChange}
        numInputs={4}
      />
      <button
        disabled={otp.length !== 4}
        onClick={__handleSubmit}
        type="submit"
        className="btn btn-primary my-3 mt-4 w-100"
      >
        {t("Validate")}
      </button>
    </div>
  );
};

export default OtpForm;
