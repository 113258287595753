// import { useEffect } from "react";
import {
  // convertTo5Multiple,
  formatPrice,
} from "../utils/utils";

const PriceDetail = ({ price, fee }) => {
  const serviceFee = (fee * price) / 100;
  const total = serviceFee + price;

  return (
    <div className="d-flex border-bottom small text-muted">
      <div className="d-flex flex-column">
        <div>Prix : {formatPrice(price)}</div>
        <div>Frais de service : {formatPrice(serviceFee)}</div>
        <div>Total : {formatPrice(total)}</div>
      </div>
    </div>
  );
};

export default PriceDetail;
