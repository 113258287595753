/* eslint-disable no-unused-vars */
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../components/AdminContainer";
import ItemPaymentForm from "../components/card/ItemPaymentForm";
import AddItemForm from "../components/form/AddItemForm";
import EditItemForm from "../components/form/EditItemForm";
import StripeItemForm from "../components/form/StripeItemForm";
import StripePaymentForm from "../components/form/StripePaymentForm";
import CircularLoader from "../components/loader/CircularLoader";
import ShopCart from "../components/shop/ShopCart";
import ShopCommandList from "../components/shop/ShopCommandList";
import ShopItem from "../components/shop/ShopItem";
import useLanguage from "../hook/useLanguage";
import {
  addCart,
  getCarts,
  getCartsAdmin,
  removeCart,
} from "../redux/slices/cartSlice";
import { getCommandsAdmin } from "../redux/slices/commandSlice";
import { getItems } from "../redux/slices/itemSlice";
import "./../styles/ShopPage.css";

const ShopPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const itemData = useSelector((state) => state.item);
  const cartData = useSelector((state) => state.cart);
  const userData = useSelector((state) => state.user);
  const commandData = useSelector((state) => state.command);
  const dispatch = useDispatch();
  const shopCartRef = useRef();
  const [step, setStep] = useState(0);
  const [onAdd, setOnAdd] = useState(false);
  const [toEdit, setToEdit] = useState(false);

  const isAdmin = 1;

  useEffect(() => {
    if (!itemData) dispatch(getItems());
    if (!cartData) dispatch(getCartsAdmin({ reset: false, interval: null }));
    if (!commandData)
      dispatch(getCommandsAdmin({ reset: false, interval: null }));
  }, [cartData, commandData, dispatch, itemData]);

  const handleOpenCart = () => {
    shopCartRef.current.classList.add("open");
  };

  const handleAddToCart = (item) => {
    return dispatch(addCart(item));
  };

  const handleRemove = (cart) => {
    return dispatch(removeCart(cart));
  };

  const handleOnPay = () => {
    setStep(1);
  };

  const [manage, setOnManage] = useState(false);

  return (
    <AdminContainer>
      {!onAdd && !toEdit ? (
        <>
          {step === 0 ? (
            itemData !== null ? (
              <div className="">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="fw-bold text-light text-uppercase">
                    Boutique
                  </h4>
                  <div className="d-flex gap-2">
                    {cartData &&
                      userData &&
                      cartData.length !== 0 &&
                      cartData.filter(
                        (cart) =>
                          cart.command_id === null &&
                          cart.user_id === userData.id
                      ).length !== 0 && (
                        <button
                          onClick={handleOpenCart}
                          className="btn openCart fw-bold text-uppercase"
                        >
                          <i className="fa fa-shopping-bag me-1"></i> mon panier
                        </button>
                      )}

                    {userData && userData.admin === isAdmin.toString() && (
                      <button
                        onClick={() => setOnAdd(true)}
                        className="btn border openCart fw-bold text-uppercase"
                      >
                        {" "}
                        <i className="fa fa-plus"></i> ajouter
                      </button>
                    )}
                    {userData && userData.admin === isAdmin.toString() && (
                      <button
                        onClick={() => setOnManage(!manage)}
                        className="btn border openCart fw-bold text-uppercase"
                      >
                        {" "}
                        <i
                          className={!manage ? "fa fa-eye" : "fa fa-eye-slash"}
                        ></i>{" "}
                        gérer
                      </button>
                    )}
                  </div>
                </div>
                {!manage ? (
                  <div className="d-flex justify-content-center flex-wrap">
                    {itemData.map((item, key) => {
                      return (
                        <div
                          key={item.key}
                          className={
                            "ShopItemContainer " + (key > 2 ? "mt-3" : "")
                          }
                        >
                          <ShopItem
                            editable={
                              userData && userData.admin === isAdmin.toString()
                            }
                            onEdit={setToEdit}
                            onAddToCart={handleAddToCart}
                            item={item}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <ShopCommandList />
                )}
              </div>
            ) : (
              <CircularLoader />
            )
          ) : userData !== null ? (
            <ItemPaymentForm
              carts={cartData.filter(
                (cart) =>
                  cart.command_id === null && cart.user_id === userData.id
              )}
              onCancel={() => setStep(0)}
            />
          ) : (
            <CircularLoader />
          )}

          {cartData &&
            cartData.length !== 0 &&
            cartData.filter(
              (cart) => cart.command_id === null && cart.user_id === userData.id
            ).length !==
              0(
                <ShopCart
                  onPay={handleOnPay}
                  onRemove={handleRemove}
                  carts={cartData.filter(
                    (cart) =>
                      cart.command_id === null && cart.user_id === userData.id
                  )}
                  ref={shopCartRef}
                />
              )}
        </>
      ) : onAdd && !toEdit ? (
        <AddItemForm
          className={"col-12 col-lg-8"}
          onBack={() => setOnAdd(false)}
        />
      ) : (
        <EditItemForm
          item={toEdit}
          className={"col-12 col-lg-8"}
          onBack={() => setToEdit(false)}
        />
      )}
    </AdminContainer>
  );
};

export default ShopPage;
