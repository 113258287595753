import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import SubscriptionsService from "../../services/SubscriptionsService";
import { handlePayment, updatePayment } from "./paymentSlice";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getSubscriptions = createAsyncThunk(
  "subscriptions/all",
  async () => {
    const res = await SubscriptionsService.getAll();
    //console.log(res.data);
    return res.data;
  }
);

export const getSubscriptionsAdmin = createAsyncThunk(
  "subscriptions/all",
  async ({ reset, interval }) => {

    if (reset) {
      return initialState
    }
    // console.log(interval);
    const res = await SubscriptionsService.getAllAdmin(interval);
    return res.data;
  }
);

export const generateCard = createAsyncThunk(
  "subscriptions/generate",
  async (subscription) => {
    // console.log(subscription);
    const res = await SubscriptionsService.generate(subscription);
    //console.log(res.data);
    return res.data.data;
  }
);


const subscriptionsSlice = createSlice({
  name: "subscription",
  initialState,
  extraReducers: {
    [getSubscriptions.fulfilled]: (state, action) => {
      return action.payload
    },
    [getSubscriptionsAdmin.fulfilled]: (state, action) => {
      return action.payload
    },
    [generateCard.fulfilled]: (state, action) => {
      const index = state.findIndex(subscription => subscription.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
      return state
    },
    [handlePayment.fulfilled]: (state, action) => {
      if (action.payload.data?.subscription) {
        if (state === null) {
          state = []
        }
        if (action.payload.data.subscription) {
          state.push(action.payload.data.subscription)
        }
      }
      return state
    },
    [updatePayment.fulfilled]: (state, action) => {
      const subscription = action.payload.data?.subscription;
      if (subscription) {
        if (state === null) {
          return [subscription]
        }
        return state.map(item => {
          if (parseInt(item.id)===parseInt(subscription.id)) return subscription;
          return item;
        })
      }
      return state;
    },
  },
});

const { reducer } = subscriptionsSlice;
export default reducer;