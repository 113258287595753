import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const addComplaint = (complaint)=> {
    return axios.post('complaints', complaint, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data)=>{
        // console.log(data.data);
        return data;
    })
}

const getAll = () => {
    return axios.get(`admin/complaints`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const ComplaintService = {
    addComplaint, getAll
}

export default ComplaintService;