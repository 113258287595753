import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CommandService from "../../services/CommandService.js";
import { handlePayment } from "./paymentSlice";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getCommands = createAsyncThunk(
  "commands/all",
  async () => {
    const res = await CommandService.getAll();
    //console.log(res.data);
    return res.data;
  }
);

export const getCommandsAdmin = createAsyncThunk(
  "commands/all",
  async ({ reset, interval }) => {
      if (reset) {
          return initialState
      }
      const res = await CommandService.getAllAdmin(interval);
      //console.log(res.data);
      return res.data;
  }
);

export const markedAsDelivered = createAsyncThunk(
  "commands/delivered",
  async (command) => {
      const res = await CommandService.markedAsDelivered(command);
      //console.log(res.data);
      return res.data;
  }
);


const commandSlice = createSlice({
  name: "command",
  initialState,
  extraReducers: {
    [getCommands.fulfilled]: (state, action) => {
        return action.payload
    },
    [handlePayment.fulfilled]: (state, action) => {
      if(action.payload.data.command){
        if (state===null)
          return [action.payload.data.command]
        state.push(action.payload.data.command)
      }
      return state
    },
    [markedAsDelivered.fulfilled]: (state, action) => {
      const command = action.payload;
      if (state === null) {
          return [command]
      }
      return state.map(item => {
          if (parseInt(item.id)===parseInt(command.id)) return command;
          return item;
      })
  },
  },
});

const { reducer } = commandSlice;
export default reducer;