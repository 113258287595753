import { useEffect, useState, useRef  } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createComplaint } from '../../redux/slices/complaintSlice';
import { toast } from "react-toastify";
import './floatButton.css';


const FloatButton = () => {
    const [showFloatBox, setShowFloatBox] = useState(false);
    const [messages, setMessages] = useState([]);
    const userData = useSelector((state) => state.user);
    const [formData, setFormData] = useState({
        object: '',
        description: '',
    });

    const messageBoxRef = useRef(null);
    const messageInputRef = useRef(null);
    const sendMsgRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (messageBoxRef.current) {
            messageBoxRef.current.scrollTop = messageBoxRef.current.scrollHeight;
        }
    }, [messages]);

    const toggleBox = (events) => {
        setShowFloatBox(!showFloatBox)
    }

    const handleSetObject = (object) => {
        let message = '';

        switch (object) {
            case 'Problème':
                message = 'J\'ai un problème avec le site.';
                break;
            case 'Suggetion':
                message = 'J\'ai une suggestion pour améliorer le site.';
                break;
            case 'Autre':
                message = 'J\'ai une autre question ou demande.';
                break;
            default:
                break;
        }

        setFormData({
            ...formData,
            object: object
        });

        // messageInputRef.current.focus = true;
        setMessages([...messages, `${message}`]);
    }

    const handleSubmit = (events) => {
        setMessages([...messages, `${formData.description}`]);
        sendMsgRef.current.disabled = true;
        
        dispatch(createComplaint({
            ...formData
        })).unwrap()
            .then((data) => {
                
                setFormData({
                    object: '',
                    description: '',
                });
                setShowFloatBox(false);
                toast.success('Le message est envoyé merci!.');
                sendMsgRef.current.disabled = true;

            }).catch((err) => {
                toast.error('Ooops une erreur! veuillez réessayer plus tard');
                sendMsgRef.current.disabled = true;
            }).finally(() => {
            })
    }

    return <div className='float_button_parent'>
        <div className={`float_box ${showFloatBox ? 'show_float_box' : ''}`}>
            <div className='float_box_head'>
                <div className='ms-2 fs-5 ' style={{ color: 'var(--primaryColor)' }}>
                    <img src={'/yafoot-club.png'} className='me-2' style={{ width: '30px' }} alt='Yafoot Club' />
                    Aide
                </div>
                <div className='close_float_box me-2' onClick={(toggleBox)}><i className="fa-solid fa-minus"></i></div>
            </div>

            <div className='msg_box fw-light p-2 w-100' ref={messageBoxRef}>
                <div className='msg_txt w-80 show'>
                    Bonjour <span className='user_name'>{userData ? userData.first_name : 'visiteur'}</span> <br />
                    <small style={{ color: 'var(--primaryColor)', fontSize: '12px' }}>Veuillez choisir une option</small>
                </div>
                <div className='msg_txt msg_option w-80 mt-2 show' onClick={() => { handleSetObject('Problème') }}>
                    <span className='me-2'>
                        <i className="fa-solid fa-exclamation" style={{ color: '#f44336' }}></i>
                    </span>
                    Un Problème ?
                </div>
                <div className='msg_txt msg_option w-80 mt-2 show' onClick={() => { handleSetObject('Suggetion') }}>
                    <span className='me-2'>
                        <i className="fa-solid fa-lightbulb" style={{ color: 'var(--primaryColor)' }}></i>
                    </span>
                    Une Suggetion ?
                </div>
                <div className='msg_txt msg_option w-80 mt-2 last_option show' onClick={() => { handleSetObject('Autre') }} >
                    <span className='me-2'>
                        <i className="fa-regular fa-comment" style={{ color: '#00bcd4' }}></i>
                    </span>
                    Autre chose ?
                </div>

                {messages.map((message, index) => (
                    <div key={index} className='msg_txt w-80 mt-2 msg_send show' style={{ alignSelf: 'flex-end' }}>{message}</div>
                ))}
            </div>

            <div className='float_box_footer'>
                <input className='ps-2' placeholder={formData.object === '' ? '...' : 'entrez votre message'} value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} disabled={formData.object === ''}  ref={messageInputRef} />
                <button style={{ width: '10%', background:'none', border:'none' }} onClick={handleSubmit} disabled={formData.description === ''}  ref={sendMsgRef}>
                    <svg viewBox="0 0 22 22">
                        <g stroke="none" strokeWidth='1' fill="none" fillRule='evenodd'>
                            <g transform="translate(-5.000000, -5.000000)" fill="#fbcb4b">
                                <g>
                                    <g transform="translate(5.000000, 5.000000)">
                                        <path d="M2.0300068,0.145970044 L20.9662955,9.37015518 C22.3445682,10.0420071 22.3445682,11.9582654 20.9662955,12.6296618 L2.0300068,21.853847 C1.09728834,22.3084288 0,21.6475087 0,20.6317597 L0.806953417,13.8945654 C0.882225434,13.2659853 1.39089595,12.7699536 2.03608467,12.6957083 L12.0229514,11.6795038 C12.8612292,11.5943266 12.8612292,10.4054904 12.0229514,10.3203132 L2.03608467,9.30410872 C1.39089595,9.23031889 0.882225434,8.7342872 0.806953417,8.10525162 L0,1.36805729 C0,0.352308292 1.09728834,-0.3081563 2.0300068,0.145970044"></path>
                                    </g>
                                </g>
                            </g>
                        </g>
                    </svg>
                </button>
            </div>
        </div>
        <button className='float_btn bg-light' onClick={(toggleBox)}>
            <i className="fa-regular fa-comment me-2" style={{ color: 'var(--primaryColor)' }}></i> Aide
        </button>
    </div>
}

export default FloatButton;