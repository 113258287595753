import axios from "axios"
import { formatDate } from "../utils/utils";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = () => {
    return axios.get(`payments`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const getAllAdmin = (interval = [new Date(), new Date()]) => {
    let url = `admin/payments`


    if (interval) {
        const startDate = formatDate(interval[0])
        const endDate = formatDate(interval[1])
        url = `admin/payments?startDate=${startDate}&endDate=${endDate}`
    }
    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const get = (id) => {
    return axios.get(`payments/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const handle = (payment) => {
    return axios.get(`${process.env.REACT_APP_API_BASE_URL}token`).then((token) => {
        return axios.post('handle-payment', { ...payment, _token: token.data },
            {
                headers: {
                    "X-CSRF-TOKEN": token.data,
                    "Authorization": `Bearer ${localStorage.getItem("authToken")}`
                }
            }).then((data) => {
                return data;
            })
    })
}
const check = (reference) => {
    return axios.get(`check-payment/${reference}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}` ?? undefined
        }
    }).then((data) => {
        return data;
    })
}


const PaymentService = {
    getAll,
    handle,
    check,
    get,
    getAllAdmin
}

export default PaymentService;