import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { formatPrice } from "../../utils/utils";
import { SpinnerLoader } from "../loader/SpinnerLoader";
import "./../../styles/ShopItem.css";

const ShopItem = ({
  editable = false,
  actionnabe = true,
  item,
  onEdit,
  onAddToCart,
  ...props
}) => {
  const {
    image,
    name,
    price,
    // description, quantity
  } = item;
  const [onAdd, setOnAdd] = useState(false);

  const handleAdd = async (e) => {
    if (onAdd) return;
    if (actionnabe) setOnAdd(true);
    await onAddToCart({ ...item, item_id: item.id })
      .unwrap()
      .then((response) => {
        toast.success(`${name} a été ajouté au panier.`);
      })
      .catch((err) => {
        toast.warn(`${name} n'a pas pu etre ajouté au panier.`);
        // console.log(err);
      })
      .finally(() => {
        setOnAdd(false);
      });
  };

  useEffect(() => {}, []);

  return (
    <div
      className="ShopItem p-3 d-flex flex-column justify-content-between"
      {...props}
    >
      {editable && (
        <i
          onClick={() => onEdit(item)}
          className="ShopItem__edit fa fa-edit ms-auto fa-1x"
        ></i>
      )}
      <div className="ShopItem__image d-flex justify-content-between align-items-center">
        <img className="img-fluid mx-auto" src={image} alt="" />
      </div>
      <div className="ShopItem__meta d-flex flex-column">
        <h5 className="text-uppercase">{name}</h5>
        <div className="d-flex justify-content-between align-items-center">
          <div className="text-dark">{formatPrice(price)}</div>
          <div
            onClick={handleAdd}
            className="ShopItem__meta__AddToCartBtn p-2 d-flex justify-content-center align-items-center"
          >
            {!onAdd ? (
              <>
                <i className="fa fa-cart-shopping"></i>{" "}
                <span className="ms-1"> ajouter</span>{" "}
              </>
            ) : (
              <SpinnerLoader />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopItem;
