
import './../../styles/CardData.css'
import './../../styles/RevenuCard.css'

const RevenuCard = ({ icon, value, label, ...props }) => {

    return <div className='CardData p-2 gap-2 me-3 d-flex justify-content-start align-items-center'>
        <div className='CardData__image'>
            {icon}
        </div>
        <div className='d-flex flex-column me-2 p-3 justify-content- center align-items- center'>
            <div className='d-flex justify-content-between align-items-start gap-4 flex-column'>
                <div className='d-flex justify-content-between align-items-center gap-4 w-100'>
                    <div className='CardData__label fw-bold text-light text-uppercase'>{label}</div>
                    <div className='CardData__value'>{value}</div>
                </div>
                <div className='childrenContent d-flex justify-content-start align-items-center'>
                    {props.children}
                </div>
            </div>
        </div>
    </div>
}

export default RevenuCard;