/* eslint-disable jsx-a11y/anchor-is-valid */
// import { forwardRef } from 'react';
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logoutCurrentUser } from "../../redux/slices/userSlice";
import "./../../styles/TopBar.css";

const TopBar = ({ user, onToggle, ...props }) => {
  const avatarUrl = "./../images/avatar.jpg";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logoutCurrentUser()).then(() => {
      setTimeout(() => {
        window.location.href = "/login";
      }, 1500);
    });
  };

  const handleProfile = () => {
    navigate("/profil");
  };

  return (
    <div className="TopBar d-flex justify-content-between align-items-center">
      <div className="d-flex gap-5">
        <div className="TopBar__greetings gap-2 d-flex align-items-center text-uppercase">
          <div
            onClick={onToggle}
            id="sideBarToggle"
            className="d-flex align-items-center justify-content-center"
          >
            <i className="fa-solid fa-bars"></i>
          </div>
          <span className="d-none d-sm-block">
            HELLO <span className="fw-bold">{user.first_name}</span>
          </span>
        </div>
        <div className="TopBar__search d-none">
          <div className="input-group">
            <input type="text" className="form-control" />
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-search"></i>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-center gap-3">
        <i className="TopBar__notifications fa fa-bell"></i>
        <div className="dropdown">
          <span
            className="dropdown-toggle d-flex gap-1 align-items-center"
            type="button"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <img className="profile" src={user.image ?? avatarUrl} alt="" />
            {user.last_name} {user.first_name}
          </span>
          <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <NavLink
                to="/donation"
                className="dropdown-item d-block d-md-none"
              >
                <i className="fas fa-hand-holding-usd me-1"></i>Faire un don
              </NavLink>
            </li>
            <li>
              <a className="dropdown-item" onClick={handleProfile}>
                <i className="fa fa-edit"></i> Profil
              </a>
            </li>
            <li>
              <a className="dropdown-item" onClick={handleLogout}>
                <i className="fa fa-sign-out"></i> Déconnexion
              </a>
            </li>
          </ul>
        </div>
        <div className="d-none d-md-block">
          <NavLink to="/donation">
            <button className="btn btn-primary text-uppercase fw-bold">
              <i className="fas fa-hand-holding-usd me-1"></i>Faire un don
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
