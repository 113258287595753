/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AdminContainer from "../components/AdminContainer";
import PlayerCard from "../components/card/CardPlayer";
import Box from "../components/Box";
import { Modal } from "react-bootstrap";
import CircularLoader from "../components/loader/CircularLoader";
import useLanguage from "../hook/useLanguage";
import "./../styles/PlayerPage.css";
import {
  addPlayer,
  getPlayers,
  updatePlayer,
  deletePlayer,
} from "../redux/slices/playerSlice";

const PlayerPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const [playerItems, setPlayerItems] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [indexDelette, setIndexDelette] = useState(0);
  const cancelBtnRef = useRef(null);

  const [formData, setFormData] = useState({
    id: "",
    first_name: "",
    last_name: "",
    position: "",
    image: null, // state for storing the image file
    imageUrl: "", // state for storing the image URL
  });

  const [errors, setErrors] = useState({
    first_name: "",
    last_name: "",
    position: "",
    image: "",
  });

  const playerData = useSelector((state) => state.player);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const isAdmin = 1;

  useEffect(() => {
    if (playerData === null) {
      dispatch(getPlayers());
    }
  }, [dispatch, playerData]);

  useEffect(() => {
    setPlayerItems(playerData);
  }, [playerData]);

  const filteredPlayers = playerItems?.filter(
    (player) =>
      player.first_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      player.last_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePreview(reader.result);
      setFormData({
        ...formData,
        image: file,
        imageUrl: reader.result,
      });
    };
  };

  const handleModalCLose = (event) => {
    setShowModal(false);
    setShowModalUpdate(false);

    setShowModalDelete(false);
    // setImagePreview('');
  };

  const handleModalEdit = (index) => {
    setShowModal(true);
    // setImagePreview('');
    setShowModalUpdate(true);
    setFormData({
      id: filteredPlayers[index].id,
      first_name: filteredPlayers[index].first_name,
      last_name: filteredPlayers[index].last_name,
      position: filteredPlayers[index].position,
      imageUrl: filteredPlayers[index].image,
    });
    // console.log(filteredPlayers[index]);
  };

  const handleModalDelete = (index) => {
    setShowModal(true);
    setShowModalDelete(true);

    setFormData({
      ...formData,
      id: filteredPlayers[index].id,
      first_name: filteredPlayers[index].first_name,
    });

    setIndexDelette(index);
  };

  const validateForm = () => {
    let errors = {
      first_name: "",
      last_name: "",
      position: "",
      image: "",
    };

    let isValid = true;

    if (!formData.first_name) {
      errors.first_name = "First name est requis";
      isValid = false;
    }

    if (!formData.last_name) {
      errors.last_name = "Last name est requis";
      isValid = false;
    }

    if (!formData.position) {
      errors.position = "Position est requis";
      isValid = false;
    }

    // if (!formData.image) {
    //     errors.image = 'Image est requis';
    //     isValid = false;
    // }

    setErrors(errors);

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = formData;

    if (validateForm()) {
      event.target.querySelector(".spinner-grow").classList.remove("d-none");
      event.target.disabled = true;
      cancelBtnRef.current.disabled = true;

      if (showModalUpdate) {
        dispatch(
          updatePlayer({
            ...data,
          })
        )
          .unwrap()
          .then((data) => {
            event.target.querySelector(".spinner-grow").classList.add("d-none");
            event.target.disabled = false;
            setShowModal(false);
            setImagePreview("");
            setFormData({
              first_name: "",
              last_name: "",
              position: "",
              image: null,
              imageUrl: "",
            });
            toast.success("Le joueur a été modifié.");
            cancelBtnRef.current.disabled = false;
          })
          .catch((err) => {
            console.log(err);
            toast.error("Veuillez remplir correctement le formulaire");
            cancelBtnRef.current.disabled = false;
          })
          .finally(() => {
            event.target.querySelector(".spinner-grow").classList.add("d-none");
            event.target.disabled = false;
          });

        return true;
      }

      if (showModalDelete) {
        return true;
        // const DeletePlayers = dispatch(deletePlayer(data.id));

        // DeletePlayers
        //     .then((json) => {
        //         console.log(json);
        //         if (json.error) {
        //             let data = json.error.data;
        //             if (data.errors) {
        //                 toast.error(data.message);
        //             } else {
        //                 console.log(json.error);
        //             }
        //         } else {
        //             toast.success("vous avez supprimé la période numéro " + Player.id);
        //         }
        //     })
        //     .finally(() => {

        //     });

        // return true;
      }

      dispatch(
        addPlayer({
          ...data,
        })
      )
        .unwrap()
        .then((data) => {
          event.target.querySelector(".spinner-grow").classList.add("d-none");
          event.target.disabled = false;
          setShowModal(false);
          setImagePreview("");
          setFormData({
            id: "",
            first_name: "",
            last_name: "",
            position: "",
            image: null,
            imageUrl: "",
          });
          toast.success("Le joueur a été crée.");

          cancelBtnRef.current.disabled = false;
        })
        .catch((err) => {
          console.log(err);
          toast.error("Veuillez remplir correctement le formulaire");
          cancelBtnRef.current.disabled = false;
        })
        .finally(() => {
          event.target.querySelector(".spinner-grow").classList.add("d-none");
          event.target.disabled = false;
        });

      return true;
    }

    if (indexDelette !== 0) {
      dispatch(deletePlayer(data.id))
        .then((data) => {
          setShowModal(false);
          toast.success("Le joueur a été modifié.");
        })
        .catch((err) => {
          console.log(err);
          toast.error("Une erreur est survenu pendant la suppression");
          cancelBtnRef.current.disabled = false;
        })
        .finally(() => {
          event.target.querySelector(".spinner-grow").classList.add("d-none");
          event.target.disabled = false;
        });

      return true;
    }
  };

  return (
    <AdminContainer>
      <div className="">
        <nav className="navbar navbar-expand-lg bg-transparent position-sticky top-0">
          <div className="container-fluid w-100 p-0">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <h2 className="navbar-brand text-light m-0" href="#">
                JOUEURS
              </h2>
              <form
                className="d-flex ms-5"
                style={{ background: "#616667", borderRadius: "5px" }}
                role="search"
              >
                <input
                  className="form-control searchBar bg-transparent"
                  style={{ color: "#fff", border: "none" }}
                  type="text"
                  placeholder="Rechercher un joueur"
                  aria-label="Search"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <div className=" bg-transparent py-2 ps-0 pe-2">
                  <i className="fa-solid fa-magnifying-glass text-light"></i>
                </div>
              </form>
            </div>
            {userData && userData.admin === isAdmin.toString() ? (
              <button
                className="btn bg-transparent text-light"
                onClick={() => setShowModal(true)}
              >
                <i className="fa-solid fa-user-plus me-2"> </i>
                Nouveau Joueur
              </button>
            ) : null}
          </div>
        </nav>

        <div
          className="container-fluid w-100 p-0"
          style={{ minHeight: "100vh", overflow: "hidden" }}
        >
          {filteredPlayers ? (
            <div className="row row-cols-1 row-cols-md-4 g-5 pt-3">
              {filteredPlayers.map((player, index) => (
                <PlayerCard
                  isAdmin={userData && userData.admin === isAdmin.toString()}
                  player={player}
                  key={index}
                  onEdit={() => handleModalEdit(index)}
                  onDelete={() => handleModalDelete(index)}
                />
              ))}
            </div>
          ) : (
            <Box content={<CircularLoader />} />
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalCLose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>
            {!showModalDelete
              ? !showModalUpdate
                ? "Ajouter un joueur"
                : "Modifier " + formData.first_name
              : "Supprimer " + formData.first_name}{" "}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showModalDelete && (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-6">
                  <label htmlFor="firstName">Prénom</label>
                  <input
                    type="text"
                    className="form-control text-dark bg-transparent"
                    id="firstName"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleFormChange}
                  />
                  {errors.first_name && (
                    <div className="text-danger">{errors.first_name}</div>
                  )}
                </div>
                <div className="form-group col-6">
                  <label htmlFor="lastName">Nom de famille</label>
                  <input
                    type="text"
                    className="form-control text-dark bg-transparent"
                    id="lastName"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleFormChange}
                  />
                  {errors.last_name && (
                    <div className="text-danger">{errors.last_name}</div>
                  )}
                </div>
              </div>
              <div className="form-group mt-3">
                <label htmlFor="position">Position</label>
                <input
                  type="text"
                  className="form-control text-dark bg-transparent"
                  id="position"
                  name="position"
                  value={formData.position}
                  onChange={handleFormChange}
                />
                {errors.position && (
                  <div className="text-danger">{errors.position}</div>
                )}
              </div>
              <div className="form-group mt-3">
                {!imagePreview && <label htmlFor="image">Image</label>}
                {imagePreview && (
                  <img
                    className="img-thumbnail"
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: "50%" }}
                  />
                )}
                <input
                  type="file" // change input type to 'file'
                  className="form-control text-dark"
                  id="image"
                  name="image"
                  style={{ color: "#000" }}
                  onChange={handleImageChange} // add onChange handler
                />
                {errors.image && (
                  <div className="text-danger">{errors.image}</div>
                )}
              </div>
            </form>
          )}
          {showModalDelete && (
            <div className="text-start fs-5 fw-bold">
              Voulez vous supprimer Ce joueur?
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleModalCLose}
            ref={cancelBtnRef}
          >
            Annuler
          </button>
          <button className="btn btn-primary" onClick={handleSubmit}>
            <div
              className="spinner-grow spinner-grow-sm d-none mx-2"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            {!showModalDelete ? "Enregistrer" : "Supprimer"}
          </button>
        </Modal.Footer>
      </Modal>
    </AdminContainer>
  );
};

export default PlayerPage;
