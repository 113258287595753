/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import axios from "axios";
import fileDownload from "js-file-download";
import jsPDF from "jspdf";
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AdminContainer from "../components/AdminContainer";
import Box from "../components/Box";
import DownloadViewer from "../components/DownloadViewer";
import ImageCarousel from "../components/history/ImageCarousel";
import ImageViewer from "../components/ImageViewer";
import CircularLoader from "../components/loader/CircularLoader";
import { Modal } from "../components/Modal";
import TabPane from "../components/navigation/TabPane";
import useLanguage from "../hook/useLanguage";
import { getCards } from "../redux/slices/cardSlice";
import { getCarts } from "../redux/slices/cartSlice";
import { getCommands, markedAsDelivered } from "../redux/slices/commandSlice";
import { getItems } from "../redux/slices/itemSlice";
import { getPayments } from "../redux/slices/paymentSlice";
import { getPrintRequests } from "../redux/slices/printRequestSlice";
import { getSeasons } from "../redux/slices/seasonSlice";
import {
  generateCard,
  getSubscriptions,
} from "../redux/slices/subscriptionSlice";
import {
  formatPrice,
  getCommandCart,
  getCommandPayment,
  getModelById,
  getSubscriptionPayment,
  getSubscriptionPrintRequest,
  parsePaymentMode,
} from "../utils/utils";
import "./../styles/PaymentPage.css";
import { PAYMENT_STATUS } from "../utils/constants";

const PaymentPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const cardData = useSelector((state) => state.card);
  const subscriptionData = useSelector((state) => state.subscription);
  const commandData = useSelector((state) => state.command);
  const cartData = useSelector((state) => state.cart);
  const paymentData = useSelector((state) => state.payment);
  const seasonData = useSelector((state) => state.season);
  const printData = useSelector((state) => state.printRequest);
  const itemData = useSelector((state) => state.item);
  const userData = useSelector((state) => state.user);

  const [imageViewer, setImageViewer] = useState(null);
  const [seasonViewer, setSeasonViewer] = useState(null);
  // const [subscriptionToPrint, setSubscriptionToPrint] = useState(null);
  const [subscriptionToGenerate, setSubscriptionToGenerate] = useState(null);
  const [subscriptionToDownload, setSubscriptionToDownload] = useState(null);
  const [preview, setPreview] = useState();
  const [openCarousel, setOpenCarousel] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (cardData === null) dispatch(getCards());
    if (paymentData === null || paymentData.length === 0)
      dispatch(getPayments());
    if (seasonData === null) dispatch(getSeasons());
    if (subscriptionData === null || subscriptionData.length === 0)
      dispatch(getSubscriptions());
    if (commandData === null || commandData.length === 0)
      dispatch(getCommands());
    if (printData === null || printData.length === 0)
      dispatch(getPrintRequests());
    if (itemData === null) dispatch(getItems());
    if (cartData === null) dispatch(getCarts());
  }, []);

  useEffect(() => {
    const cardIdToPreview = localStorage.getItem("cardIdToPreview");

    if (!(seasonData && subscriptionData && cardIdToPreview)) return;

    localStorage.removeItem("cardIdToPreview");
    const previewItem = subscriptionData.filter(
      (val) => parseInt(val.id) === parseInt(cardIdToPreview)
    )[0];
    setImageViewer(previewItem.image);
    setSeasonViewer(getModelById(cardData, previewItem.card_id).verso);
    toast.success(
      "Votre YAFOOT carte a été générée, vous pouvez la télécharger ou l'envoyer en impression pour que nous l'imprimions pour vous.",
      {
        autoClose: true,
      }
    );
  }, [cardData, seasonData, subscriptionData]);

  
  // handle incoming command
  useEffect(() => {
    const commandIdToPreview = localStorage.getItem("commandIdToPreview");

    if (!(commandData && commandIdToPreview)) return;

    localStorage.removeItem("commandIdToPreview");
    setKey(1);
    toast.success("Votre commande a bien été validé.", {
      autoClose: true,
    });
  }, [commandData]);



  useEffect(() => {
    if (subscriptionToGenerate) {
      const eventHTMLContent = subscriptionToGenerate.event.target.innerHTML;
      subscriptionToGenerate.event.target.innerHTML = "...";
      subscriptionToGenerate.event.target.setAttribute("disabled", "true");

      dispatch(generateCard(subscriptionToGenerate.subscription))
        .unwrap()
        .then((data) => {
          // let season = getModelById(seasonData, data.season_id);
          setImageViewer(data.image);
          setSeasonViewer(getModelById(cardData, data.card_id).verso);
          toast.success(
            "Votre YAFOOT carte a été générée, vous pouvez la télécharger ou l'envoyer en impression pour que nous l'imprimions pour vous.",
            {
              autoClose: false,
            }
          );
        })
        .catch((err) => {
          toast.error(
            "Erreur lors de la génération, veuillez réessayer, si ce problème persite veuillez nous contacter.",
            {
              autoClose: false,
            }
          );
        })
        .finally(() => {
          subscriptionToGenerate.event.target.removeAttribute("disabled");
          subscriptionToGenerate.event.target.innerHTML = eventHTMLContent;
          setSubscriptionToGenerate(null);
        });
    }
  }, [cardData, dispatch, subscriptionToGenerate]);

  const commandDataColumns = [
    {
      name: "Mode de paiement",
      selector: (row) => {
        const payment = getCommandPayment(row.id, paymentData);
        if (!payment) return "unknow";
        return <div>{parsePaymentMode(payment.payment_method)}</div>;
      },
    },
    {
      name: "Articles",
      selector: (row) => {
        let carts = getCommandCart(cartData, row.id);
        let items = carts.map((cart) => getModelById(itemData, cart.item_id));
        let item_name = items.map((item) => item.name).join(",");

        return (
          <div
            className="article_list_hover"
            style={{ cursor: "pointer" }}
            onClick={() => setOpenCarousel([items, carts])}
          >
            {" "}
            {item_name}{" "}
          </div>
        );
      },
    },
    {
      name: "Quantité",
      selector: (row) => {
        let carts = getCommandCart(cartData, row.id);
        return carts.reduce((prev, next) => {
          return prev + next.quantity;
        }, 0);
      },
    },
    {
      name: "Prix Total",
      selector: (row) => {
        return formatPrice(row.price);
      },
    },

    {
      name: "Statut",
      selector: (row) => {
        let statut;
        let color;
        const payment = getCommandPayment(row.id, paymentData);
        if (!payment) return "unknow";
        if (payment.status === PAYMENT_STATUS.PROGRESS) {
          statut = "en cours";
          color = "#008A20";
        } else if (payment.status === PAYMENT_STATUS.REJECTED) {
          statut = "annulé";
          color = "red";
        } else if (payment.status === PAYMENT_STATUS.RECEIVED) {
          statut = "complété";
          color = "#00eea1";
        }

        return (
          <div className="error" style={{ color: color, fontWeight: "bold" }}>
            {statut}
          </div>
        );
      },
    },
    {
      name: "état".toLocaleUpperCase(),
      selector: (row) => {
        const delivered = row.delivered === 1;
        if (delivered) {
          return <div>livré</div>;
        }
        return <div>non livré</div>;
      },
    },
    {
      name: "Actions",
      selector: (row) => {
        let carts = getCommandCart(cartData, row.id);
        let items = carts.map((cart) => getModelById(itemData, cart.item_id));
        const delivered = row.delivered === 1;
        return (
          <div className="d-flex justify-content-around w-100">
            <button
              onClick={() => {
                setOpenCarousel([items, carts]);
              }}
              title="visualisez voz articles"
              style={{ color: "#f5f5f5", backgroundColor: "#00a1ff" }}
              className="actionBtn"
            >
              <i className="fa fa-eye" aria-hidden="true"></i>
            </button>

            {!delivered &&
              userData.admin === 1 &&
              userData.super_admin === 1 && (
                <button
                  title="marqué comme livré"
                  onClick={(e) => handleMarkAsDelivered(e, row)}
                  style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }}
                  className="actionBtn mx-1"
                >
                  {" "}
                  <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                </button>
              )}
          </div>
        );
      },
    },
  ];

  const subscriptionDataColumns = [
    {
      name: "Date",
      selector: (row) => {
        let image = row.image;
        let printRequest = getSubscriptionPrintRequest(row.id, printData);
        let payment = getSubscriptionPayment(row.id, paymentData);
        let output;
        if (payment !== null && payment?.status === PAYMENT_STATUS.PROGRESS) {
          output = (
            <i
              title="paiement en attente"
              style={{ color: "##0720cf" }}
              className="fa fa-info-circle"
              aria-hidden="true"
            ></i>
          );
        } else if (payment !== null && payment?.status === PAYMENT_STATUS.RECEIVED) {
          if (!image) {
            output = (
              <i
                title="non générée"
                style={{ color: "#df554e" }}
                className="fa fa-info-circle"
                aria-hidden="true"
              ></i>
            );
          } else if (printRequest) {
            if (printRequest.printed_at) {
              output = (
                <i
                  title="impriméé"
                  style={{ color: "#00eea1" }}
                  className="fa fa-xmark"
                  aria-hidden="true"
                ></i>
              );
            } else {
              output = (
                <i
                  title="impression en cours"
                  style={{ color: "#ee44a1" }}
                  className="fa fa-print"
                  aria-hidden="true"
                ></i>
              );
            }
          } else {
            output = (
              <i
                title="générée"
                style={{ color: "#00eea1" }}
                className="fa fa-check-circle"
                aria-hidden="true"
              ></i>
            );
          }
        } else {
          output = (
            <i
              title="paiement rejété"
              style={{ color: "#cf2007" }}
              className="fa fa-xmark"
              aria-hidden="true"
            ></i>
          );
        }
        return (
          <>
            {output} {row.created_at.split("T")[0]}
          </>
        );
      },
      sortable: true,
      sortFunction: (a, b) => {
        return a.created_at > b.created_at;
      },
    },
    {
      name: "Type de carte",
      selector: (row) => getModelById(cardData, row.card_id)?.type,
      sortable: true,
    },
    {
      name: "Aperçu",
      selector: (row) => {
        let image = row.image;
        let payment = getSubscriptionPayment(row.id, paymentData);
        // let season = getModelById(seasonData, row.season_id);
        // console.log(season);
        if (payment !== null && payment.status === PAYMENT_STATUS.PROGRESS) {
          return (
            <div className="error" style={{ color: "#FFCC00" }}>
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
              paiement en attente
            </div>
          );
        } else if (payment !== null && payment.status === PAYMENT_STATUS.RECEIVED) {
          if (image) {
            return (
              <img
                onClick={() => {
                  setImageViewer(row.image);
                  setSeasonViewer(getModelById(cardData, row.card_id).verso);
                }}
                style={{ cursor: "pointer" }}
                src={image}
                className="img-fluid w-50"
                alt={""}
              />
            );
          }
          return (
            <div className="error" style={{ color: "#FFCC00" }}>
              <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
              carte non générée
            </div>
          );
        }
      },
    },
    {
      name: "Saison",
      selector: (row) =>
        getModelById(seasonData, row.season_id)?.name.split(" ")[1],
      sortable: true,
    },
    {
      name: "Prix",
      selector: (row) => {
        // console.log(getModelById(seasonData, row.season_id));
        // console.log(getModelById(cardData, row.card_id));
        return formatPrice(getModelById(cardData, row?.card_id)?.card_price);
      },
      sortable: true,
    },
    {
      name: "Mode",
      selector: (row) => {
        const mode = parsePaymentMode(
          getSubscriptionPayment(row?.id, paymentData)?.payment_method
        );
        return mode;
      },
    },
    {
      name: "Actions",
      selector: (row) => {
        let payment = getSubscriptionPayment(row.id, paymentData);
        let printRequest = getSubscriptionPrintRequest(row.id, printData);
        if (payment !== null && payment.status === PAYMENT_STATUS.RECEIVED) {
          if (row.image) {
            return (
              <div className="d-flex" style={{width: "690px"}}>
                <button
                  onClick={() => {
                    setImageViewer(row.image);
                    setSeasonViewer(getModelById(cardData, row.card_id).verso);
                  }}
                  title="visualisez votre carte"
                  style={{ color: "#f5f5f5", backgroundColor: "#00a1ff" }}
                  className="actionBtn"
                >
                  <i className="fa fa-eye" aria-hidden="true"></i>
                </button>
                <button
                  disabled={printRequest !== null}
                  title="imprimez votre carte"
                  onClick={() => handlePrintCard(row)}
                  style={{ color: "#f5f5f5", backgroundColor: "#ee44a1" }}
                  className="mx-1 actionBtn"
                >
                  <i className="fa fa-print" aria-hidden="true"></i>
                </button>
                <button
                  title="téléchargez votre carte"
                  onClick={(e) => handleDownloadCard(e, row)}
                  style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }}
                  className="actionBtn"
                >
                  <i className="fa fa-download" aria-hidden="true"></i>
                </button>
                <button
                  title="régénérez votre carte"
                  onClick={(e) => handleGenerateCard(e, row)}
                  style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }}
                  className="actionBtn mx-1"
                >
                  {" "}
                  <i className="fa fa-refresh" aria-hidden="true"></i>{" "}
                </button>
              </div>
            );
          } else {
            return (
              <div className="d-flex justify-content-center">
                <button
                  title="générez votre carte"
                  onClick={(e) => handleGenerateCard(e, row)}
                  style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }}
                  className="actionBtn mx-auto"
                >
                  généré <i className="fa fa-refresh" aria-hidden="true"></i>
                </button>
              </div>
            );
          }
        } else if (payment !== null && payment.status === PAYMENT_STATUS.PROGRESS) {
          return (
            <div className="d-flex justify-content-around w-100">
              <button
                disabled
                onClick={() => setImageViewer(row.image)}
                style={{ color: "#f5f5f5", backgroundColor: "#00a1ff" }}
                className="actionBtn"
              >
                <i className="fa fa-eye" aria-hidden="true"></i>
              </button>
              <button
                disabled
                onClick={() => handlePrintCard(row)}
                style={{ color: "#f5f5f5", backgroundColor: "#ee44a1" }}
                className="mx-1 actionBtn"
              >
                <i className="fa fa-print" aria-hidden="true"></i>
              </button>
              <button
                disabled
                onClick={() => handleDownloadCard(row.image)}
                style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }}
                className="actionBtn"
              >
                <i className="fa fa-download" aria-hidden="true"></i>
              </button>
              <button
                disabled
                onClick={(e) => handleGenerateCard(e, row)}
                style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }}
                className="actionBtn mx-1"
              >
                {" "}
                <i className="fa fa-refresh" aria-hidden="true"></i>{" "}
              </button>
            </div>
          );
        }
      },
    },
  ];

  const handleGenerateCard = (e, row) => {
    setSubscriptionToGenerate({ event: e, subscription: row });
  };

  const handlePrintCard = () => {};

  const handleDownloadCardPDF = async () => {
    let subscription = subscriptionToDownload;
    // var files = [null, null]
    var reader1 = new FileReader();
    var reader2 = new FileReader();

    const doc = new jsPDF({
      orientation: "p",
      format: [326, 503],
    });

    reader1.onloadend = function () {
      doc.addImage(reader1.result, "png", 0, 0, 326, 503);
    };

    reader2.onloadend = function () {
      doc.addPage([326, 503], "p");
      doc.addImage(reader2.result, "png", 0, 0, 326, 503);
      doc.save("yafoot-carte.pdf");
    };

    // download card image
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}token`)
      .then((response) => {
        axios({
          url: `${process.env.REACT_APP_API_BASE_URL}download-card/${subscription.id}`,
          method: "get",
          headers: {
            "X-CSRF-TOKEN": response.data,
          },
          responseType: "blob",
        })
          .then((res) => {
            reader1.readAsDataURL(res.data);
          })
          .finally(() => {});
      });

    // download season image
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}token`)
      .then((response) => {
        axios({
          url: `${process.env.REACT_APP_API_BASE_URL}download-season/${subscription.id}`,
          method: "get",
          headers: {
            "X-CSRF-TOKEN": response.data,
          },
          responseType: "blob",
        })
          .then((res) => {
            // console.log(res);
            // const season = getModelById(seasonData, subscriptionData.season_id)
            // season.image.split('seareader1.readAsDataURL(res.data);files[1] = res.data;
            reader2.readAsDataURL(res.data);
            // fileDownload(res.data, "verso-yafoot-card.png")
          })
          .finally(() => {
            // setOnDownload(false)
          });
      });
  };

  const handleDownloadCardImage = async () => {
    let subscription = subscriptionToDownload;
    // download card image
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}token`)
      .then((response) => {
        axios({
          url: `${process.env.REACT_APP_API_BASE_URL}download-card/${subscription.id}`,
          method: "get",
          headers: {
            "X-CSRF-TOKEN": response.data,
          },
          responseType: "blob",
        })
          .then((res) => {
            // console.log(res);
            fileDownload(res.data, subscription.image.split("cards/")[1]);
          })
          .finally(() => {});
      });

    // download season image
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}token`)
      .then((response) => {
        axios({
          url: `${process.env.REACT_APP_API_BASE_URL}download-season/${subscription.id}`,
          method: "get",
          headers: {
            "X-CSRF-TOKEN": response.data,
          },
          responseType: "blob",
        })
          .then((res) => {
            // console.log(res);
            // const season = getModelById(seasonData, subscriptionData.season_id)
            // season.image.split('seasons/')[1]
            fileDownload(res.data, "verso-yafoot-card.png");
          })
          .finally(() => {
            // setOnDownload(false)
          });
      });
  };

  const handleDownloadCard = (e, subscription) => {
    setSubscriptionToDownload(subscription);
  };

  const handleMarkAsDelivered = (e, command) => {
    setConfirm({
      title: "Confirmation",
      message: `voulez-vous vraiment marqué cette commande comme livré ? (cette action est irréversible)`,
      callback: function (ev) {
        ev.preventDefault();
        ev.stopPropagation();
        ev.target.setAttribute("disabled", "true");
        ev.target.innerHTML =
          "<div className='spinner-grow spinner-grow-sm' role='status'></div>";

        // start voting for this player
        setConfirm(null);
        e.target.innerHTML =
          "<div className='spinner-grow spinner-grow-sm' role='status'></div>";
        e.target.setAttribute("disabled", true);
        dispatch(markedAsDelivered({ ...command }))
          .then(() => {
            toast.success("Cette commande a été marqué comme livré.");
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            e.target.innerHTML = "";
            e.target.innerHTML = '<i className="fa fa-shopping-cart"></i>';
            e.target.removeAttribute("disabled");
          });
      },
    });
  };

  const [key, setKey] = useState(0);
  const [confirm, setConfirm] = useState(null);

  return (
    
    <AdminContainer>
      {subscriptionData !== null &&
      commandData &&
      cardData !== null &&
      cartData !== null &&
      itemData !== null &&
      seasonData !== null &&
      seasonData !== null &&
      printData !== null &&
      paymentData !== null ? (
        <TabPane
          onSelect={(val) => setKey(val)}
          activeKey={key}
          child1={
            <div className="">
              <h4 className="fw-bold text-light text-uppercase">
                historique des paiements
              </h4>
              <DataTable
                className="PaymentHistory"
                columns={subscriptionDataColumns}
                data={subscriptionData}
                striped
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              />
            </div>
          }
          child2={
            <div className="">
              <h4 className="fw-bold text-light text-uppercase">
                historique des commandes
              </h4>
              <DataTable
                className="PaymentHistory"
                columns={commandDataColumns}
                data={commandData}
                striped
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              />
            </div>
          }
          child3={
            <div className="">
              <h4 className="fw-bold text-light text-uppercase">
                historique des dons
              </h4>
              <DataTable
                className="PaymentHistory"
                columns={commandDataColumns}
                data={commandData}
                striped
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              />
            </div>
          }
        />
      ) : (
        <Box content={<CircularLoader />} />
      )}

      {imageViewer && (
        <ImageViewer
          image={imageViewer}
          season={seasonViewer}
          onClose={() => {
            setImageViewer(null);
            setSeasonViewer(null);
            setPreview(null);
          }}
          open={subscriptionToDownload !== null}
        />
      )}

      {subscriptionToDownload && (
        <DownloadViewer
          onDownloadImage={handleDownloadCardImage}
          onDownloadPDF={handleDownloadCardPDF}
          onClose={() => setSubscriptionToDownload(null)}
          open={subscriptionToDownload !== null}
        />
      )}
      {openCarousel && (
        <div
          className={
            "ImageViewer d-flex align-items-center justify-content-center"
          }
        >
          <div
            onClick={() => {
              setOpenCarousel(null);
            }}
            className="cross d-flex justify-content-center shadow align-items-center"
          >
            <i className="fa fa-close" aria-hidden="true"></i>
          </div>
          <ImageCarousel items={openCarousel} />
        </div>
      )}
      {confirm && (
        <Modal
          onClose={(e) => {
            setConfirm(null);
          }}
          open={confirm ?? false}
          title={
            <div className="text-uppercase" style={{ color: "#e89f29" }}>
              <i className="fas fa-exclamation-circle fa-1x"></i>{" "}
              {confirm.title}
            </div>
          }
        >
          {!confirm.onConfirm && (
            <div className="d-flex flex-column justify-content-between p-3">
              <div>{confirm.message}</div>
              <div className="d-flex justify-content-end w-100 mt-3">
                <button
                  style={{ background: "#e89f29 !important" }}
                  className="downloadBtn warning text-uppercase"
                  onClick={(e) => confirm.callback(e)}
                >
                  Confirmer
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </AdminContainer>
  );
};

export default PaymentPage;
