import axios from "axios"
import { formatDate } from "../utils/utils";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = () => {
    return axios.get(`commands`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        // console.log(data.data);
        return data;
    })
}

const getAllAdmin = (interval) => {
    let url = `admin/commands`

    if (interval) {
        const startDate = formatDate(interval[0])
        const endDate = formatDate(interval[1])
        url = `admin/commands?startDate=${startDate}&endDate=${endDate}`
    }
    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const markedAsDelivered = (command) => {
    let url = `admin/commands/markasdelivered/${command.id}`

    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const CommandService = {
    getAll,
    getAllAdmin,
    markedAsDelivered
}

export default CommandService;