/* eslint-disable no-unused-vars */
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import LoginPage from "./pages/LoginPage";
import i18n from "./localisation/strings";

function App() {
  return <LoginPage />;
}

export default App;
