import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import DonationService from "../../services/DonationService.js";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getDonations = createAsyncThunk(
    "donation/all",
    async () => {
        const res = await DonationService.getAll();
        return res.data;
    }
);
export const createDonation = createAsyncThunk(
    'donation/create',
    async (don, { rejectWithValue }) => {
        try {
            const res = await DonationService.createDonation(don);
            return res.data
        } catch (err) {
            return rejectWithValue({
                errors: err.errors
            })
        }
    }
)



const donationSlice = createSlice({
    name: "donation",
    initialState,
    extraReducers: {
        [getDonations.fulfilled]: (state, action) => {
            return action.payload
        },
    },
});

const { reducer } = donationSlice;
export default reducer;