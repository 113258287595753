import { Link } from "react-router-dom";
import "./../../styles/SideBar.css";
// import useLanguage from '../../hook/useLanguage';
import SideBarItem from "./SideBarItem";
import { useSelector } from "react-redux";

const SideBar = ({ admin = false, ...props }) => {
  // const [language, t, handleChangeLanguage] = useLanguage();
  const userData = useSelector((state) => state.user);

  const SideBarRouter = [
    {
      path: "/dashboard",
      icon: <i className="bi bi-grid"></i>,
      title: "Tableau de bord",
      disable: false,
    },
    // {
    //     path: '/income',
    //     icon: <i className="bi bi-wallet2"></i>,
    //     title: 'Revenus',
    //     disable: false
    // },
    {
      path: "/card",
      icon: <i className="bi bi-person-vcard-fill"></i>,
      title: "Cartes",
      disable: false,
    },
    {
      path: "/payment",
      icon: <i className="fa fa-dollar"></i>,
      title: "Paiement",
      disable: false,
    },
    {
      path: "/profil",
      icon: <i className="fa fa-edit"></i>,
      title: "Profil",
      disable: false,
    },
    {
      path: "/shop",
      icon: <i className="fa fa-shopping-cart"></i>,
      title: "Boutique",
      disable: false,
    },
    {
      path: "/member",
      icon: <i className="fa fa-user-group"></i>,
      title: "Membres",
      disable: false,
    },
    {
      path: "/candidates",
      icon: <i className="fa fa-user-group"></i>,
      title: "Candidats",
      disable: false,
    },
    {
      path: "/participations",
      icon: <i className="fa-solid fa-futbol"></i>,
      title: "Participations",
      disable: false,
    },
    {
      path: "/player",
      icon: <i className="fa-solid fa-futbol"></i>,
      title: "Joueurs",
      disable: false,
    },

    {
      path: "/academy",
      icon: <i className="fa-solid fa-school"></i>,
      title: "Academie",
      disable: false,
    },

    {
      path: "/vote",
      icon: <i className="fa fa-vote-yea"></i>,
      title: "Votes",
      disable: false,
    },
    {
      path: "/game",
      icon: <i className="fa-solid fa-layer-group"></i>,
      title: "Match",
      disable: false,
    },
    {
      path: "/complaint",
      icon: <i className="bi bi-info-circle"></i>,
      title: "Plaintes",
      disable: false,
    },
    {
      path: "/donation",
      icon: <i className="fas fa-hand-holding-usd"></i>,
      title: "Faire un don",
      disable: false,
    },
  ];

  const applySuperAdminRouteRestrictions = (routes, user) => {
    const restrictedRoutes = ["/dashboard", "/member", "/complaint",  "/candidates"];
    const superAdmin = parseInt(user.admin) === 1;
    if (!superAdmin) {
      return routes.filter((route) => {
        return restrictedRoutes.indexOf(route.path) === -1;
      });
    }
    return routes;
  };

  return (
    <div className="SideBar">
      <Link to={'/'}>
        <div className="SideBar__brand py-1 d-flex justify-content-center align-items-center">
          <img src="./yafoot-club.png" alt="" />
        </div>
      </Link>
      
      <div className="SideBar__navigator d-flex flex-column gap-3">
        {applySuperAdminRouteRestrictions(SideBarRouter, userData).map(
          ({ path, icon, title, disable }, index) => {
            return (
              <SideBarItem
                key={index}
                to={path}
                icon={icon}
                title={title}
                disable={disable}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default SideBar;
