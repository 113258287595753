import AppContainer from "../components/AppContainer";
import "./../styles/HomePage.css";
import "./../styles/NotFoundPage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <AppContainer>
      <video className="mainVideo" autoPlay loop muted>
        <source src="./../videos/bg-home.mp4"></source>
        Your browser does not support video
      </video>

      <div id="homePage" className="homeContent p-0 m-0">
        <div
          id="homeSection"
          className="homeContent__item homeContent__home d-flex align-items-center"
        >
          <div className="container-fluid">
            <div className="d-flex ms-lg-5 flex-column justify-content-center h-100 col-12 col-md-8 col-lg-4">
              <img
                className="brand ms-2"
                src="./yafoot-club.png"
                alt="Yafoot Club"
              />
              <h1 className="text-light">Ooops ! 404</h1>

              <NavLink to={"/"}>
                <button className="btn btn-primary mt-3 text-white col-9 col-md-8 col-lg-6">
                  Revenir à l'accueil
                </button>
              </NavLink>
            </div>
            <div className="bonBaDjob d-flex">
              <div className="bonBaDjob__content d-flex justify-content-between gap-3 align-items-center">
                <div className="d-flex gap-3">
                  {Array(7)
                    .fill(0)
                    .map((e, x) => (
                      <div key={x} className="verticalLine"></div>
                    ))}
                </div>
                {/* BON BA DJOB */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default NotFoundPage;
