import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import CandidatePaymentService from "../../services/CandidatePaymentService";

const initialState = null;

export const getCandidatePayments = createAsyncThunk(
  "candidatePayment/all",
  async ({ reset }) => {
    if (reset) {
      return initialState;
    }
    const res = await CandidatePaymentService.getAll()
    return res;
  }
);

const candidatePaymentSlice = createSlice({
  name: "candidatePayment",
  initialState,
  extraReducers: {
    [getCandidatePayments.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

const { reducer } = candidatePaymentSlice;
export default reducer;
