import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = ()=>{
    return axios.get(`cards`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data)=>{
        // console.log(data.data);
        return data;
    })
}



const CardService = {
    getAll,

}

export default CardService;