import { NavLink } from 'react-router-dom';
// import useLanguage from '../../hook/useLanguage';

const SideBarItem = ({ to, icon, title, disable, ...props }) => {
    // const [language, t, handleChangeLanguage] = useLanguage()

    return <>
        {disable ? <div style={{color:'white'}} className='NavLink__container d-flex w-100 p-2 align-items-center gap-3'>
            <div className='NavLink__container__icon d-flex justify-content-end'>{icon}</div>
            <div className='NavLink__container__title'> {title}</div>
        </div> : <NavLink className={'SideBarItem'} to={to}>
            <div className='NavLink__container d-flex w-100 p-2 align-items-center gap-3'>
                <div className='NavLink__container__icon d-flex justify-content-end'>{icon}</div>
                <div className='NavLink__container__title'> {title}</div>
            </div>
        </NavLink>}
    </>
}   

export default SideBarItem;